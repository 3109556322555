<div class="navbar-wrapper">
  <div class="navbar-container">
    <div class="navbar-top__flexible">
      <img src="https://s3.ap-south-1.amazonaws.com/docvita.com/docvita_logo_transparent_bg.png" alt="logo"
        class="docvita-logo" />
      <div class="dropdown_positon__mobile ms-auto">
        <ng-content *ngTemplateOutlet="menuDropper"></ng-content>
      </div>
    </div>


    <div class="menu">
      <ng-container *ngFor="let option of dashBoardOptions | keyvalue: returnZero">
        <div class="menu-option" [ngClass]="{
            'menu-option-selected': selectedOption == option.value.route
          }" (click)="gotoRoute(option.value.route)">
          <span class="menu-label">
            {{ option.value.label }}
          </span>
          <span class="unread-messages-bubble ms-2" *ngIf="option?.value?.notifCount">
            {{option?.value?.notifCount}}
          </span>
        </div>
      </ng-container>

    </div>
    <div class="dropdown_positon__desktop">
      <ng-content *ngTemplateOutlet="menuDropper"></ng-content>
    </div>


  </div>
</div>

<ng-template #menuDropper>
  <button class="burger border-start" (click)="toggleDropDown()">
    <span style="color: #777">
      {{
      providerProfile.getFirstNameWithSalutation() ? providerProfile.getFirstNameWithSalutation() :
      (providerProfile.fullName()?providerProfile.fullName():"xxxxxxxx")
      }}
    </span>
    <span class="material-icons ml-1" style="color: #777; font-size: 1.3rem">
      arrow_drop_down
    </span>
    <div class="dropdown-card" *ngIf="dropDown">
      <div class="dropdown-option" [ngClass]="{
          'dropdown-option-selected': selectedOption == option.value.route
        }" *ngFor="let option of dropDownOptions | keyvalue: returnZero" (click)="gotoRoute(option.value.route)">
        <span class="material-icons drop-icon">
          {{ option.value.icon }}
        </span>
        <span class="drop-label">{{ option.value.label }}</span>
      </div>
      <div class="gray-line"></div>
      <div class="dropdown-option" [ngClass]="{
          'dropdown-option-selected': selectedOption == option.value.route
        }" *ngFor="let option of dashBoardBottomOptions | keyvalue: returnZero"
        (click)="gotoRoute(option.value.route)">
        <span class="material-icons drop-icon">
          {{ option.value.icon }}
        </span>
        <span class="drop-label">{{ option.value.label }}</span>
      </div>
      <div class="drop-logout-option" (click)="logout()">
        <span class="material-icons drop-icon"> logout </span>
        <span class="drop-label">Logout</span>
      </div>
      <!-- <div class="drop-profile-card">
                  <div class="drop-profile-desc"> Logged in as:</div>
                  <div class="drop-profile-name">{{providerProfile.fullName()?providerProfile.fullName():'xxxxxxxx'}}</div>
                  
              </div> -->
    </div>
  </button>
</ng-template>