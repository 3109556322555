import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as dayjs from 'dayjs';
import * as timezone from 'dayjs/plugin/timezone';
import * as utc from 'dayjs/plugin/utc';
import { ScheduleService } from 'src/app/manage-schedule-interface/services/schedule/schedule.service';
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.tz.setDefault('Asia/Kolkata');

import { DoctorListingProfile } from '../../../shared/models-ts/Actors/Doctor/DoctorListingProfile';
import { ScheduleHelper } from '../../../shared/models-ts/Actors/Doctor/ScheduleHelper';
import { DayjsHelper } from '../../models-ts/Helpers/dayjsHelper';
import { AppointmentType } from '../../models-ts/Enums/Enums';

@Component({
  selector: 'provider-available-slots',
  templateUrl: './provider-available-slots.component.html',
  styleUrls: ['./provider-available-slots.component.scss'],
})
export class ProviderAvailableSlotsComponent implements OnInit {
  @Input() set doctorProfile(val: DoctorListingProfile) {
    this._doctorProfile = val;
    this.populateSlots();
  }
  @Input() set date(val: string) {
    this._date = val;
    this.populateSlots();
  }
  @Input() set mode(val: AppointmentType) {
    this._mode = val;
    this.populateSlots();
  }

  get doctorProfile() {
    return this._doctorProfile;

  }
  get date() {
    return this._date;
  }
  get mode() {
    return this._mode;
  }
  @Input() disableContainer: boolean = false;
  @Output() selectedSlot: EventEmitter<any> = new EventEmitter<any>();
  @Output() allSlots: EventEmitter<any[]> = new EventEmitter<any>();
  _date: string = new Date().toISOString().split('T')[0];
  _doctorProfile: DoctorListingProfile = new DoctorListingProfile();
  _mode: AppointmentType = AppointmentType.online;
  currentSlots: any[] = [];
  slotLoader: boolean = false;
  noSlots: boolean = false;
  _selectedSlot: any;

  constructor(
    private scheduleService:ScheduleService
  ) {}

  ngOnInit(): void {}

  populatePracticeOptions() {
    let practiceOptions = [];
    if (this.doctorProfile?.associatedPractices?.size > 0) {
      for (const i of this.doctorProfile?.associatedPractices) {
        if (i[1]?.acceptingAppointmentsOnDocVita) {
          practiceOptions.push({ key: i[0], value: i[1].name })
        }
      }
      if (practiceOptions?.length > 0) return practiceOptions[0]["key"];
      return null;
    } else {
      return null;
    }
  }

  async populateSlots() {
    try {
      this.currentSlots = [];
      this.noSlots = false;
      
      if (
        this.doctorProfile &&
        this.doctorProfile.id &&
        this.date
      ) {
        this.slotLoader = true;
        const slots = await this.scheduleService.getThreeDaySchedule([this.date],this.doctorProfile?.id, this.mode == AppointmentType.inPerson? this.populatePracticeOptions(): null);

        if(slots == null || slots[this.date] == null){
          return;
        }else{
          this.currentSlots = slots[this.date] 
          if (this.currentSlots.length == 0) {
            this.noSlots = true;
          }
          this.slotLoader = false;
        }

      } else {
        this.noSlots = true;
        this.slotLoader = false;
      }
      this.allSlots.emit(this.currentSlots);
    } catch (error) {
      const errMessage = error['message'] || 'unknown_error'
      switch(errMessage){
        case 'no_schedule':
        case 'not_the_latest_call':
        case 'missing_params':
        default:{
          this.slotLoader = false;
        }
      }
    }
  }

  selectSlot(slot) {
    this._selectedSlot = slot;
    this.selectedSlot.emit(slot);
  }
  getTimeInHHmma(timestring: string) {
    let dayjsHelper = new DayjsHelper();
    return dayjsHelper.HHmmtohhmma(timestring);
  }
}
