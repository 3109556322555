import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-dv-custom-radio",
  templateUrl: "./dv-custom-radio.component.html",
  styleUrls: ["./dv-custom-radio.component.scss"],
})
export class DvCustomRadioComponent {
  @Input() checked: boolean;

  constructor() {}
}
