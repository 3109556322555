import { Component, Input, OnInit } from '@angular/core';
import { DoctorListingProfile } from '../../shared/models-ts/Actors/Doctor/DoctorListingProfile';

@Component({
  selector: 'in-person-location-icon',
  templateUrl: './in-person-location-icon.component.html',
  styleUrls: ['./in-person-location-icon.component.scss']
})
export class InPersonLocationIconComponent implements OnInit {

  @Input() practiceId:string;

  @Input() providerProfile:DoctorListingProfile;

  constructor() { }

  ngOnInit(): void {
  }

  get locationNameForId(){
    if( this.practiceId && 
      this.providerProfile?.associatedPractices && 
      this.providerProfile?.associatedPractices?.size>0 && 
      this.providerProfile?.associatedPractices?.has(this.practiceId)
    ){
      return this.providerProfile?.associatedPractices?.get(this.practiceId).name;
    }else{
      return 'In-Person'
    }
    
  }





}
