import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppointmentsComponent } from './components/dashboard/appointments/appointments.component';
import { DashboardMainComponent } from './components/dashboard/dashboard-main/dashboard-main.component';
import { HelpSupportComponent } from './components/dashboard/help-support/help-support.component';
import { IntegrationsComponent } from './components/dashboard/integrations/integrations.component';
import { SettingsComponent } from './components/dashboard/settings/settings.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./auth/auth.module').then(
        (m) => m.AuthModule
      ),
  },
  {
    path: 'dashboard/:path',
    component: DashboardMainComponent,
    children: [
      {
        path: '**',
        redirectTo: '',
      },
    ],
  },
  {
    path: 'calls',
    loadChildren: () =>
      import('./p2p-calls/p2p-calls.module').then(
        (m) => m.P2pCallsModule
      ),
  },
  {
    path: 'appointments',
    component: AppointmentsComponent,
  },
  {
    path: 'integrations',
    component: IntegrationsComponent,
  },
  {
    path: 'settings',
    component: SettingsComponent,
  },
  {
    path: 'support',
    component: HelpSupportComponent,
  },
  {
    path: 'payouts',
    loadChildren: () =>
      import("./session-reports/session-reports.module")
        .then((m) => m.SessionReportsModule)
  },
  { path: 'availability', loadChildren: () => import('./availability/availability.module').then(m => m.AvailabilityModule) },
  {
    path: '**',
    redirectTo: '',
  },
  // {
  //   path:'',
  //   component:''
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    // anchorScrolling: 'enabled',
    onSameUrlNavigation: 'reload',
    // scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'legacy',
  },)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
