export class UrlHelpers {
  public urls(
    baseUrl: string,
    crmBaseUrl: string,
    listingUrl: string,
    docvitaOneUrl: string,
    cloudFunctionsUrl: string
  ) {
    return {
      'reauth-check': baseUrl + '/v1/provider-auth/reauth-check',
      'send-provider-otp': baseUrl + '/v1/provider-auth/send-otp',
      'verify-provider-otp': baseUrl + '/v1/provider-auth/verify-otp',
      'resend-provider-otp': baseUrl + '/v1/provider-auth/resend-otp',
      'googlemeet-auth-url': baseUrl + '/v1/google-oauth-service/get-auth-url',
      'googlemeet-deauth': baseUrl + '/v1/google-oauth-service/deauth-endpoint',
      'zoom-auth-url': baseUrl + '/v1/zoom-service/get-auth-url',
      'zoom-deauth': baseUrl + '/v1/zoom-service/deauth-endpoint',
      'oauth-redirect-url': docvitaOneUrl + '/dashboard/integrations',
      'get-google-userinfo': baseUrl + '/v1/google-oauth-service/user-info',
      'get-zoom-userinfo': baseUrl + '/v1/zoom-service/user-info',
      'create-googlemeet-link':
        baseUrl + '/v1/meeting-link-service/create-googlemeet-link',
      'create-zoom-link': baseUrl + '/v1/meeting-link-service/create-zoom-link',
      'update-consult-config-json':
        crmBaseUrl + '/v1/leads/update-appointment-json',
      'cancel-appointment': baseUrl + '/v3/appointment/cancel',
      'reschedule-appointment': baseUrl + '/v3/appointment/reschedule',
      'book-appointment': baseUrl + '/v3/appointment/book',
      'create-patient-profile':
        baseUrl + '/v1/patient/profile/self-registration',
      'send-slack-whatsapp-channel-trigger':
        listingUrl + '/v1/wa/slack/send-message',
      'analytics-login':
        listingUrl + "/v1/provider-webapp/analytics/login",
      'analytics-logout':
        listingUrl + "/v1/provider-webapp/analytics/logout",
      'analytics-opened':
        listingUrl + "/v1/provider-webapp/analytics/opened",
      'analytics-view-client-info':
        listingUrl + "/v1/provider-webapp/analytics/view-client-info",
      'payouts-pdf': listingUrl+ '/v1/passbook/generate/pdf'
    };
  }
}
