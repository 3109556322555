<ng-container >
    <div class="main-wrapper">
        <div class="top-section">
            <button class="back-btn" (click)="gotoMenu()">
                <span class="material-icons back-btn-icon">
                arrow_back_ios
                </span>
            </button>
            <div class="route-label">Settings</div>    
        </div>
    </div>
</ng-container>