<div class="white-card d-flex flex-column"
    [ngClass]="{'white-card': !replaceCardWithBottomBorder, 'bottom-border-section':replaceCardWithBottomBorder}">
    <div class="d-flex justify-content-between align-items-center" (click)="toggleCardOpenClose()"
        [ngClass]="{pointer: isAfterThisStep && !finalProcessLoader}">
        <div class="section-title" [ngClass]="{isAtThisStep: isAtThisStep, isAfterThisStep:isAfterThisStep}">
            {{thisStepNumber + 1}}. {{title}}
            <img [src]="greenCheckIconPath" alt="green-check" class="green-check ms-2" *ngIf="isAfterThisStep">
        </div>
        <ng-container *ngIf="!showChangeButton && isAfterThisStep && !finalProcessLoader">
            <img [src]="chevronLeftIconPath" alt="arrow"
                [ngClass]="{'open-card-arrow': !isCollapsed, 'closed-card-arrow': isCollapsed}">
        </ng-container>
        <ng-container *ngIf="showChangeButton && isCollapsed && isAfterThisStep && !finalProcessLoader">
            <span class="change-text">Change</span>
        </ng-container>
    </div>
    <div [@collapse]="isCollapsed">
        <ng-content></ng-content>
    </div>
    <ng-container *ngIf="isAfterThisStep && isCollapsed">
        <ng-container [ngTemplateOutlet]="completedCollapsedContent"></ng-container>
        <!-- <ng-content select="completedCollapsedContent"></ng-content> -->
    </ng-container>
</div>