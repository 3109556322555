import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root',
})
export class SessionStorageService {
  constructor() {}
  getItem(k) {
    if (sessionStorage.getItem(k)) {
      return JSON.parse(sessionStorage.getItem(k));
    } else {
      return null;
    }
  }
  setItem(k, v) {
    sessionStorage.setItem(k, JSON.stringify(v));
  }
  clearAll() {
    sessionStorage.clear();
  }
}
