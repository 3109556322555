import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { app } from '../app/shared/firebaseHelper/firebaseHelper';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SpinnerInfinityModule } from 'spinners-angular/spinner-infinity';
import { SpinnerCircularModule } from 'spinners-angular/spinner-circular';
import { BsModalRef, BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { HammerModule } from '@angular/platform-browser';
import { NgxPaginationModule } from 'ngx-pagination';
import { HomeComponent } from './components/dashboard/home/home.component';

import { NavbarComponent } from './components/navbar/navbar.component';
import { MenuComponent } from './components/dashboard/menu/menu.component';
import { IntegrationsComponent } from './components/dashboard/integrations/integrations.component';
import { DashboardMainComponent } from './components/dashboard/dashboard-main/dashboard-main.component';
import { SettingsComponent } from './components/dashboard/settings/settings.component';
import { HelpSupportComponent } from './components/dashboard/help-support/help-support.component';
import { AppointmentsComponent } from './components/dashboard/appointments/appointments.component';
import { RescheduleRequestModalComponent } from './components/reschedule-request-modal/reschedule-request-modal.component';
import { MeetingLinkModalComponent } from './components/meeting-link-modal/meeting-link-modal.component';
import { AppointmentDetailsComponent } from './components/dashboard/appointments/appointment-details/appointment-details.component';
import { SharedModule } from './shared/shared.module';
import { ViewClientInfoModalComponent } from './components/dashboard/appointments/view-client-info-modal/view-client-info-modal.component';
import { CancelRequestModalComponent } from './components/cancel-request-modal/cancel-request-modal.component';
import { HttpTokenInterceptorService } from './services/http-token-interceptor.service';
import { ScheduleAppointmentModalComponent } from './components/schedule-appointment-modal/schedule-appointment-modal.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { SharedComponentsModule } from './shared-components/shared-components.module';
import { SuperPowersModule } from './super/super.module';

declare global {
  interface Window {
    analytics: any;
    fbq: Function;
    CashFree: any;
    Razorpay: any;
    hj: any;
  }
}
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavbarComponent,
    MenuComponent,
    IntegrationsComponent,
    DashboardMainComponent,
    SettingsComponent,
    HelpSupportComponent,
    AppointmentsComponent,
    RescheduleRequestModalComponent,
    MeetingLinkModalComponent,
    AppointmentDetailsComponent,
    ViewClientInfoModalComponent,
    CancelRequestModalComponent,
    ScheduleAppointmentModalComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    // NgbModule,
    ModalModule.forRoot(),
    ButtonsModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TooltipModule.forRoot(),
    HttpClientModule,
    SpinnerCircularModule,
    SpinnerInfinityModule,
    ToastrModule.forRoot({
      timeOut: 3500,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    HammerModule,
    NgxPaginationModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    SharedComponentsModule,
    SuperPowersModule
  ],
  providers: [BsModalService, BsModalRef, {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpTokenInterceptorService,
    multi: true
  },],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    this.initFirebase();
  }
  initFirebase() {
    app;
  }
}
