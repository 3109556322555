import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ClientInfoEnum } from '../components/dashboard/appointments/view-client-info-modal/view-client-info-modal.component';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(private _http: HttpClient) {}

  private checkStatus200 = (json) => {
    if (
      json &&
      json['body'] &&
      json['statusCode'] == 200 &&
      json['body']['success'] == true
    ) {
      return true;
    }
    return false;
  };

  async viewClientInfo(providerId: string, leadId: string, clientId: string, type: ClientInfoEnum) {
    try {
      let url = environment['analytics-view-client-info'];
      let resp = await this._http
        .post(
          url, {providerId: providerId, leadId: leadId, clientId: clientId, type: type }
        )
        .toPromise();
      if (this.checkStatus200(resp) ) {
        return true;
      } else {
        return null;
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async login(providerId: string) {
    try {
      let url = environment['analytics-login'];
      let resp = await this._http
        .post(
          url, {providerId: providerId}
        )
        .toPromise();
      if (this.checkStatus200(resp) ) {
        return true;
      } else {
        return null;
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async logout(providerId: string) {
    try {
      let url = environment['analytics-logout'];
      let resp = await this._http
        .post(
          url, {providerId: providerId}
        )
        .toPromise();
      if (this.checkStatus200(resp) ) {
        return true;
      } else {
        return null;
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async opened(providerId: string) {
    try {
      let url = environment['analytics-opened'];
      let resp = await this._http
        .post(
          url, {providerId: providerId}
        )
        .toPromise();
      if (this.checkStatus200(resp) ) {
        return true;
      } else {
        return null;
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  }
}
