<div class="d-flex flex-wrap w-100 justify-content-end" style="font-size: 14px;">
    <ng-container *ngIf="!slotLoader && currentSlots?.length > 0">
        <button class="btn btn-outline-secondary p-2 m-2 slot" [disabled]="!slot['isAvailable']" *ngFor="let slot of currentSlots"
            [ngClass]="{'slot-selected': slot==_selectedSlot}" (click)="selectSlot(slot)">
            {{getTimeInHHmma(slot["startTime"])}}
        </button>
    </ng-container>
    <div *ngIf="!slotLoader && noSlots" class="text-primary ms-auto me-auto" style="font-size: 0.8em!important; text-align: center;">
        🧘🏻 No times available from your weekly schedule
    </div>
    <div class="spinner-border spinner-border-sm ms-3" role="status" *ngIf="slotLoader">
        <span class="sr-only">Loading...</span>
    </div>
</div>