import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-help-support',
  templateUrl: './help-support.component.html',
  styleUrls: ['./help-support.component.scss']
})
export class HelpSupportComponent implements OnInit {

  constructor(
    private _router:Router,
    private _route:ActivatedRoute
  ) { }

  ngOnInit(): void {
  }
  gotoMenu(){
    this._router.navigate(['../'],{relativeTo:this._route})
  }

}
