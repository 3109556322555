import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { InPersonLocationIconComponent } from './in-person-location-icon/in-person-location-icon.component';



@NgModule({
  declarations: [
    InPersonLocationIconComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    TooltipModule,
  ],
  exports:[
    InPersonLocationIconComponent
  ]
})
export class SharedComponentsModule { }
