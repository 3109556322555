import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BigLoaderService {
  loaderState: { bool: boolean; text?: string } = { bool: false, text: '' };
  loaderSubject = new BehaviorSubject<{ bool: boolean; text?: string }>(null);
  constructor() {}
  start(loaderText?: string) {
    this.loaderState = { bool: true, text: loaderText ? loaderText : null };
    this.loaderSubject.next({
      bool: true,
      text: loaderText ? loaderText : null,
    });
  }
  stop() {
    this.loaderState = { bool: false, text: null };
    this.loaderSubject.next({
      bool: false,
    });
  }
}
