export enum AccountStatus {
  Active,
  Inactive,
  Suspended,
}

export enum ProviderType {
  Doctor,
  Nurse,
  HealthcareWorker,
}

export enum PersonAddressType {
  Home,
  Office,
}

export enum EntityType {
  Clinic,
  Hospital,
}

export enum AppointmentType {
  online = "online",
  inPerson = "inPerson",
}

export enum AppointmentPlatform {
  docvita,
  whatsapp,
}

export enum FormStage {
  IntroPage,
  BasicInformation,
  CapturePhotograph,
  SelfDeclaration,
  AdditionalInformation,
  SuccessfulSubmission,
}

export enum OnlineConsultationPlatform {
  zoom = "zoom",
  whatsapp = "whatsapp",
  phone = "phone",
  googlemeet = "googlemeet",
}

export enum OneUserType {
  doctor,
  specialist,
  healthWorker,
  clinicOwner,
  manager,
  hospitalOwner,
  hospitalAdmin,
}

export enum ReschedulingRequestType {
  provider_to_client = "provider_to_client",
  client_to_provider = "client_to_provider",
  docvita_team = "docvita_team",
}

export enum CancellationByType {
  client = "client",
  provider = "provider",
  docvita_team = "docvita_team",
}

export enum ReschedulingRequestStatus {
  created = "created",
  completed = "completed",
  denied = "denied",
  deleted = "deleted",
  cancelled = "cancelled",
}

export enum AppointmentInviteType {
  nextfollowup = "nextfollowup",
  regularbooking = "regularbooking",
}

export enum AppointmentInviteStatus {
  scheduled = "scheduled",
  created = "created",
  accepted = "accepted",
  booked = "booked",
  cancelled = "cancelled",
  cancelledBySystem = "cancelledBySystem",
}

export enum AppointmentInviteActionType {
  sentToClient = "sentToClient",
  acceptedByClient = "acceptedByClient",
  changeDateTimeRequest = "changeDateTimeRequest",
}

export enum AdsConversionStatus {
  reporting_pending = "reporting_pending",
  reported = "reported",
}

export enum WACallInitiatorEnum {
  client = "client",
  provider = "provider",
}

export enum WACallMediumEnum {
  audio = "audio",
  video = "video",
}

export enum ServiceTypes {
  individual = "individual",
  couple = "couple",
  family = "family",
  consultation = "consultation",
  consultation15 = "consultation15",
  consultation30 = "consultation30",
  consultation60 = "consultation60",
  freefollowup = "freefollowup",
}

interface ServiceTypeDisplayObject {
  displayName: string;
  description: string;
}

export function mapServiceTypeToDisplayObject(
  service: ServiceTypes
): ServiceTypeDisplayObject {
  switch (service) {
    case ServiceTypes.individual:
      return {
        displayName: "Individual",
        description: "1 member",
      };
    case ServiceTypes.couple:
      return {
        displayName: "Couple",
        description: "2 members",
      };
    case ServiceTypes.family:
      return {
        displayName: "Family",
        description: "2+ members",
      };
    case ServiceTypes.consultation:
      return {
        displayName: "Consultation",
        description: "1 member",
      };
    case ServiceTypes.consultation15:
      return {
        displayName: "Consultation (15 min)",
        description: "1 member",
      };
    case ServiceTypes.consultation30:
      return {
        displayName: "Consultation (30 min)",
        description: "1 member",
      };
    case ServiceTypes.consultation60:
      return {
        displayName: "Consultation (60 min)",
        description: "1 member",
      };
    case ServiceTypes.freefollowup:
      return {
        displayName: "Free Followup",
        description: "1 member",
      };
    default:
      return {
        displayName: "Consultation",
        description: "1 member",
      };
  }
}

export enum SupportTicketStatus {
  Open = "Open",
  Resolved = "Resolved",
  Closed = "Closed",
  InReview = "InReview",
}

export enum SupportTicketPlatform {
  whatsapp = "whatsapp",
}