import { Injectable, NgZone } from '@angular/core';
import { DoctorListingProfile } from '../../models-ts/Actors/Doctor/DoctorListingProfile';
import { plainToClass } from 'class-transformer';
import { auth, db } from '../../firebaseHelper/firebaseHelper';
import {
  doc,
  setDoc,
  getDoc,
  onSnapshot,
  collection,
  query,
  where,
  documentId,
  getDocs,
} from 'firebase/firestore';
import { HttpClient } from '@angular/common/http';
import { environment as env } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Appointment } from '../../models-ts/Entities/Appointment';
@Injectable({
  providedIn: 'root',
})
export class ProviderService {
  constructor(
    private _http: HttpClient,
    private _zone: NgZone
    ) {}

  async fetchProviderProfile(providerId: string) {
    try {
      // console.log("in here still trying to fetch provider")
      const docRef = doc(db, 'profiles_for_listing', providerId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists) {
        let data = docSnap.data();
        // console.log("got the data: ",data)
        return data;
      } else {
        console.log("Profile doesn't exisit!");
        return null;
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  fetchLiveProviderProfile(providerId: string) {
    return new Observable((observer) => {
      let _ = onSnapshot(
        doc(db, 'profiles_for_listing', providerId),
        (doc) => {
          this._zone.run(()=>{
            if (doc.exists) {
              observer.next(doc.data());
            } else {
              observer.error('no user.');
            }
          })
        },
        (err) => {
          this._zone.run(()=>{
            observer.error('no user');
          })
        }
      );
      return {
        unsubscribe() {
          _();
        },
      };
    });
  }

  
  async getGoogleMeetAuthUrl(providerId: string): Promise<string> {
    try {
      let redirectUri = env['oauth-redirect-url'];
      let authUrl = await this._http
        .get(
          env['googlemeet-auth-url'] +
            '?id=' +
            providerId +
            '&redirectUri=' +
            redirectUri
        )
        .toPromise();
      // console.log(this.checkStatus200(authUrl))
      if (this.checkStatus200(authUrl) && authUrl['body']['url']) {
        return authUrl['body']['url'];
      } else {
        return null;
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async removeGoogleMeetOauth(providerId: string) {
    try {
      let body = {
        providerId,
      };
      let deauth = await this._http
        .post(env['googlemeet-deauth'], body)
        .toPromise();
      if (this.checkStatus200(deauth)) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async getZoomAuthUrl(providerId: string): Promise<string> {
    try {
      let redirectUri = env['oauth-redirect-url'];
      let authUrl = await this._http
        .get(
          env['zoom-auth-url'] +
            '?id=' +
            providerId +
            '&redirectUri=' +
            redirectUri
        )
        .toPromise();
      if (this.checkStatus200(authUrl) && authUrl['body']['url']) {
        return authUrl['body']['url'];
      } else {
        return null;
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async removeZoomOauth(providerId: string) {
    try {
      let body = {
        providerId,
      };
      let deauth = await this._http.post(env['zoom-deauth'], body).toPromise();
      if (this.checkStatus200(deauth)) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async getGoogleUserInfo(providerId: string) {
    try {
      let url = env['get-google-userinfo'] + '?id=' + providerId;
      let userInfo = await this._http.get(url).toPromise();
      if (this.checkStatus200(userInfo) && userInfo['body']['userData']) {
        return userInfo['body']['userData'];
      } else {
        return null;
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async getZoomUserInfo(providerId: string) {
    try {
      let url = env['get-zoom-userinfo'] + '?id=' + providerId;
      let userInfo = await this._http.get(url).toPromise();
      if (this.checkStatus200(userInfo) && userInfo['body']['userData']) {
        return userInfo['body']['userData'];
      } else {
        return null;
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  checkStatus200 = (json) => {
    if (
      json &&
      json['body'] &&
      json['statusCode'] == 200 &&
      json['body']['success'] == true
    ) {
      return true;
    }
    return false;
  };

  async updateOnlinePlatformDetails(providerId, integrationId, json) {
    try {
      let docRef = doc(db, 'profiles_for_listing', providerId);
      let onlineMeetingDetails = {
        onlineConsultationConfig: {
          onlineConsultationPlatformConfig: {
            [integrationId]: json,
          },
        },
      };
      let setData = await setDoc(docRef, onlineMeetingDetails, { merge: true });
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async getProviderAppointmentsByDate(
    providerId: string,
    date
  ): Promise<any[]> {
    try {
      let docRef = collection(
        db,
        'organisation_data',
        providerId,
        'organisation_appointments'
      );
      let q = query(docRef, where(documentId(), '==', date));
      let orgAppointments = await getDocs(q);
      if (!orgAppointments.empty) {
        let appts = [];
        orgAppointments.forEach((doc) => {
          appts.push.apply(appts, Object.values(doc.data()));
        });
        return appts;
      } else {
        return [];
      }
    } catch (error) {
      console.log(error);
      return [];
    }
  }
}
