<!-- <ng-container > -->
<div class="top-section">
  <!-- <button class="back-btn" (click)="gotoMenu()">
                <span class="material-icons back-btn-icon">
                arrow_back_ios
                </span>
            </button> -->
  <div class="route-label">Appointments</div>
</div>
<div class="main-wrapper">
  <div class="appointments-container">
    <div class="top-panel">
      <div class="date-picker-wrapper">
        <!-- <div class="row">
          <div class="col-xs-12 col-12 col-md-4 form-group">
            <input class="form-control" #drp="bsDaterangepicker" bsDaterangepicker [(ngModel)]="bsRangeValue">
          </div>
          <div class="col-xs-12 col-12 col-md-3 form-group">
            <button class="btn btn-success" (click)="drp.toggle()" [attr.aria-expanded]="drp.isOpen" type="button">Date Range Picker</button>
          </div>
        </div> -->
        <!-- <igx-date-range-picker
          #rangePicker
          [mode]="'dialog'"
          [(ngModel)]="dateRange"
          (ngModelChange)="changeListingOpt($event)"
        >
        </igx-date-range-picker> -->
      </div>
    </div>

    <div class="appointment-container">
      <div class="appointment-container-top-panel">
        <div class="top-panel-left">
          <div
          class="top-panel-option"
          [ngClass]="{
            'top-panel-option-selected': selectedTopPanelOption == opt.key
          }"
          *ngFor="let opt of topPanelOptions | keyvalue: returnZero"
          (click)="switchToTopPanelTab(opt.key)"
        >
          {{ opt.value }}
        </div>
        <div
          class="top-panel-option"
          [ngClass]="{
            'top-panel-option-selected': selectedTopPanelOption == 'date_range'
          }"
          (click)="switchToTopPanelTab('date_range')"
        >
          <span>Date Range </span>
          <span class="date-range-text">{{
            displayDateRange
          }}</span>
          <input
            type="text"
            class="form-control custom-date-range"
            #dp="bsDaterangepicker"
            bsDaterangepicker
            [bsConfig]="bsConfig"
            [outsideClick]="true"
            [(ngModel)]="inlineRangeValue"
            (ngModelChange)="setInlineDateRange()"
          />
        </div>
        </div>
        
        <div class="top-panel-right">
          <div class="patient-search-wrapper">
            <input
              #searchInp
              placeholder="Search by name or phone number"
              [(ngModel)]="searchTerm"
              (ngModelChange)="setSearchTerm()"
              class="patient-search-input"
            />
            <span class="material-icons magnify-icon"> search </span>
          </div>
        </div>
      </div>
      <div class="appointments-list-container">
        <ng-container *ngIf="!isLoadingLeads">
          <div
            class="appointment-card-container"
            *ngFor="let item of finalLeads | paginate: pageConfig"
          >
            <div class="date-card">
              {{ item.date | date: "EEEE, dd MMM yyyy" }}
            </div>
            <app-appointment-details
              [leads]="item.leads"
              [currentlyDroppedLead]="currentlyDroppedLead"
              (setLeadToDrop)="setLeadToDrop($event)"
              [providerProfile]="providerProfile"
              (populateAppointments)="populateAppointments()"
            ></app-appointment-details>
          </div>
        </ng-container>

        <div
          class="no-data-badge"
          *ngIf="finalLeads.length == 0 && !isLoadingLeads"
        >
          <span class="material-icons ndb-icon"> medication </span>
          No Appointments! 💤
        </div>
        <sa-spinner-circular
          [enabled]="isLoadingLeads"
          color="#5952FF"
          secondaryColor="rgba(24, 24, 24, 0.09)"
          style="margin-top: 4rem"
          class="appointment-spinner"
        ></sa-spinner-circular>
      </div>
    </div>
    <div class="d-flex justify-content-end mt-2" style="width: 100%">
      <pagination-controls
        class="cc-pagination mt-2"
        maxSize="9"
        responsive="true"
        (pageChange)="pageConfig.currentPage = $event"
      ></pagination-controls>
    </div>
  </div>
</div>

<app-date-picker-modal
  [dateRange]="dateRange"
  (setDateRange)="setDateRange($event)"
  #datePickerRef
></app-date-picker-modal>
