<ng-container>
  <div class="top-section">
    <!-- <button class="back-btn" (click)="gotoMenu()">
            <span class="material-icons back-btn-icon">
            arrow_back_ios
            </span>
        </button> -->
    <div class="route-label">Help & Support</div>
  </div>
  <div class="main-wrapper">
    <div class="wip-container">
      <!-- <span class="material-icons wip-icon">
                engineering
            </span>
            <div class="wip-text">
                New updates..Coming soon!
            </div> -->
      <img
        src=".././../../../assets/soon_illstrn.svg"
        alt="coming soon"
        class="wip-icon"
      />
      <div style="display: flex; flex-direction: column">
        <div class="coming-soon-txt">Coming Soon!</div>
        <div class="wip-text">The app is still in the making. Stay tuned!</div>
      </div>
    </div>
  </div>
</ng-container>
