import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { listingUrl } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChatAppService {

  constructor(
    private _http:HttpClient
  ) { }

  async getTotalUnreadCountForProvider(providerId:string){
    // 
    try {
      if(!providerId){
        return null;
      }
      const url = listingUrl + '/v1/sendbird-chat/client-app/get-total-unread-count-for-provider?providerId=' + providerId;
      const unreadCountData = await this._http.get(url).toPromise();
      if(unreadCountData && unreadCountData['statusCode']==200 && unreadCountData['body'] && unreadCountData['body']['data']){
        return unreadCountData['body']['data']['unreadCount']?+unreadCountData['body']['data']['unreadCount']:0;
      }else{
        return null;
      }
    } catch (error) {
      console.log(error)
      return null;
    }
  }

}
