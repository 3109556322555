<div class="modal-header">
    <h4 class="modal-title pull-left"><span class="material-icons" style="
          color: rgb(161, 161, 161);
          font-size: 0.95em;
          margin-right: 0.25em;
        ">
            link
        </span>
        <span class="ml-1">{{title}}</span>
    </h4>
</div>
<div class="modal-body">
    <div class="d-flex flex-column w-100">
        <ng-container *ngIf="!creatingLink && !linkCreated">
            <ng-container *ngFor="let btn of meetingLinkOpts | keyvalue: returnZero">
                <button class="d-flex align-items-center justify-content-center btn btn-outline-primary mb-2" *ngIf="toEnable(btn.key)"
                    (click)="meetingLinkButtonActions(btn.key)">
                    <img [src]="btn.value.src" alt="btn.key" style="width: 2rem;margin-right: 0.5rem;" />
                    <div>{{ btn.value.label }}</div>
                </button>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="creatingLink && !linkCreated">
            <sa-spinner-circular [enabled]="creatingLink" color="#5952FF" secondaryColor="rgba(24, 24, 24, 0.09)"
                class="ml-auto mr-auto"></sa-spinner-circular>
        </ng-container>
        <ng-container *ngIf="linkCreated">
            <div class="created-meeting-info">
                <div class="created-meeting-title">
                    <span class="material-icons" style="font-size: 0.95em">meeting_room</span>
                    <span class="ml-1">Meeting Created!</span>
                </div>
                <div class="link-container">
                    {{
                    linkToDisplay ? (linkToDisplay | stringTrim: 37) : "xxxxxxxxxx"
                    }}
                </div>
                <button class="btn btn-default" (click)="copyTheLink()">
                    <span class="material-icons" style="font-size: 0.95em">content_copy</span>
                    <span class="ml-1">Copy Link</span>
                </button>
            </div>
        </ng-container>
    </div>
    
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">Cancel</button>
</div>