import { PlatformCheckService } from 'src/app/services/platform-check/platform-check.service';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { classToPlain, plainToClass } from 'class-transformer';
import * as uuid from 'uuid';
import { SourceTracking } from 'src/app/shared/models-ts/Protocols/SourceTracking';
@Injectable({
  providedIn: 'root',
})
export class DVTrackService {
  postHogInitialised: boolean = false;
  _posthogImport: Promise<any>;
  posthog: any;
  get posthogImport(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (this.posthog) {
        resolve(this.posthog);
      } else {
        import("posthog-js").then((val) => {
          this.posthog = val.default;
          resolve(this.posthog);
        }).catch((err => {
          reject(err);
        }))
      }
    })
  }
  _sourceTracking: SourceTracking;
  get sourceTracking(): SourceTracking {
    try {
      if (window.localStorage.getItem('tracking')) {
        this._sourceTracking = plainToClass(
          SourceTracking,
          JSON.parse(window.localStorage.getItem('tracking'))
        );
      }
    } catch (e) {
      console.error("Localstorage access denied");
    }
    return this._sourceTracking;
  }
  set sourceTracking(val: SourceTracking) {
    try {
      window.localStorage.setItem('tracking', JSON.stringify(classToPlain(val)));
      this._sourceTracking = val;
    } catch (e) {
      console.error("Localstorage access denied");
    }
  }
  constructor(private _platformCheckService: PlatformCheckService) { }

  private startSession() {
    try {
      let sessionId = localStorage.getItem("sessionId");
      if (sessionId == null) {
        sessionId = uuid.v4();
        localStorage.setItem("sessionId", sessionId);
        localStorage.setItem("sessionStartedOn", new Date().getTime() + "");
        this.updateLastAccessedOn();
      } else {
        let sessionExpiry = 1 * 24 * 60 * 60 * 1000; //1 day
        let refreshTheSession = (+localStorage.getItem("sessionLastAccessedOn") + sessionExpiry < new Date().getTime());
        if (refreshTheSession) {
          sessionId = uuid.v4();
          localStorage.setItem("sessionId", sessionId);
          localStorage.setItem("sessionStartedOn", new Date().getTime() + "");
          this.updateLastAccessedOn();
        } else {
          sessionId = localStorage.getItem("sessionId");
        }
        console.log("sessionId", sessionId);
      }
    } catch (e) {
      console.error("Localstorage access denied");
    }
  }

  private updateLastAccessedOn() {
    localStorage.setItem("sessionLastAccessedOn", new Date().getTime() + "");
  }

  private clearSession() {
    try {
      localStorage.removeItem("sessionId");
      localStorage.removeItem("sessionStartedOn");
    } catch (e) {
      console.error("Localstorage access denied");
    }
  }

  startTracking() {
    if (
      !this.postHogInitialised
      // && this.shouldTrack
    ) {
      this.startSession();
      this.posthogImport.then((posthog) => {
        posthog.init('phc_vULWefnx99QnqVbO2cqMAsnLyKQt7tONwlNfeG9lGPl', {
          api_host: 'https://app.posthog.com',
        });
        this.postHogInitialised = true;
        if (this.shouldTrack) {
          var landingPageMap = this.landingPageSourceParams();
          landingPageMap['landedUrl'] = this.getLandingPageBaseUrl();
          posthog.capture('Landed', landingPageMap);
          // console.log('posthog init ');
        }
      }).catch(err => {

      })
    }
  }
  trackPageView() {
    this.posthogImport.then((posthog) => {
      if (this.shouldTrack) {
        this.updateLastAccessedOn();
        posthog.capture('$pageview');
        this.trackPageViewFB();
      }
    }).catch(err => {
      console.error(err);
    })
  }
  trackPageViewFB() {
    if (this.shouldTrackFBPage) {
      this.updateLastAccessedOn();
      window.fbq('track', 'PageView');
    }
  }

  trackHotJarEvent(title: string) {
    if (this.shouldTrack) {
      if(window.hj) {
        window.hj("event", title);
      }
    } else {
      console.log("HotJar Event", title);
    }
  }

  public get isNotLocalHost(): boolean {
    return (
      !window.location.href.includes('127.0.0.1') &&
      !window.location.href.includes('localhost') &&
      !window.location.href.includes('sencillo.in')
    );
  }

  public get shouldTrack(): boolean {
    return (
      environment.analyticsTracking &&
      this._platformCheckService.isThisClient() &&
      this.isNotLocalHost
    );
  }

  public get shouldTrackFBPage(): boolean {
    return (
      environment.analyticsTracking &&
      this._platformCheckService.isThisClient() &&
      window != null && window.fbq != null
      && !window.location.href.includes("keyword")
      && !window.location.href.includes("device")
      && !window.location.href.includes("extension")
      && this.isNotLocalHost
    );
  }

  getLandingPageBaseUrl() {
    return window.location.href.split('?')[0];
  }

  landingPageSourceParams(): any {
    var urlSplit = window.location.href.split('?');
    var map = {};
    if (urlSplit.length > 1) {
      var queryParamsArray = urlSplit[1].split('&');
      for (let q of queryParamsArray) {
        var eachPair = q.split('=');
        map[eachPair[0]] = eachPair[1];
      }
    }
    return map;
  }

  identify(userId: string, properties: any) {
    if(window && window.hj) {
      window.hj('identify', userId, properties);
    }
    this.posthogImport.then((posthog) => {
      if (this.postHogInitialised) {
        this.updateLastAccessedOn();
        posthog.identify(userId, properties);
      }
    }).catch(err=>{
      
    })
  }

  reset() {
    if (this.postHogInitialised) {
      if(window && window.hj) window.hj('identify', null,{});
      this.posthogImport.then((posthog) => {
        posthog.reset();
      }).catch(err=>{
        
      })
    }
  }

  track(event: string, meta?: any, includeSourceTracking?: boolean) {
    if(!this.postHogInitialised) {
      this.startTracking();
    }
    if (!environment.production) console.log(event, meta); //Helpful for debugging in non prod env.
    this.posthogImport.then((posthog) => {
      if (this.shouldTrack) {
        this.updateLastAccessedOn();
        if (this.sourceTracking != null && includeSourceTracking) {
          meta['gclid'] = this.sourceTracking.gclid;
          meta['device'] = this.sourceTracking.device;
          meta['campaignid'] = this.sourceTracking.campaignid;
          meta['adgroupid'] = this.sourceTracking.adgroupid;
          meta['keyword'] = this.sourceTracking.keyword;
          if (localStorage.getItem("sessionId")) meta['sessionId'] = localStorage.getItem("sessionId")
          if (localStorage.getItem("sessionStartedOn")) meta['sessionStartedOn'] = +localStorage.getItem("sessionStartedOn")
          if (localStorage.getItem("sessionLastAccessedOn")) meta['sessionLastAccessedOn'] = +localStorage.getItem("sessionLastAccessedOn")
        }
        posthog.capture(event, meta);
      }
    }).catch(err => {

    })
  }
}
