import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { DoctorListingProfile } from '../../models-ts/Actors/Doctor/DoctorListingProfile';

@Injectable({
  providedIn: 'root'
})
export class ProviderDataService {
  currentSelection: BehaviorSubject<{}>;

  constructor(
    private _router:Router
  ) {
    this.currentSelection = new BehaviorSubject(null);
  }

  updateCurrentDoctor(selectedDoctor: DoctorListingProfile) {
    this.currentSelection['provider'] = selectedDoctor;
    this.currentSelection.next(this.currentSelection);
  }

  getCurrentDoctor(): DoctorListingProfile {
    if(this.currentSelection['provider']){
      return this.currentSelection['provider'];
    }else{
      return null;
    }
  }

  updateLocalProfileField(key,value){
    let localData = JSON.parse(localStorage.getItem("docvita-one-profile"));
    if(localData){
      localData[key] = value;
      localStorage.setItem("docvita-one-profile",JSON.stringify(localData))
    }else{
      let newLocalData = {}
      newLocalData[key] = value;
      localStorage.setItem("docvita-one-profile",JSON.stringify(newLocalData))
    }
  }

  setLocalProfile(json){
    localStorage.setItem("docvita-one-profile",JSON.stringify(json));
  }

  getLocalProfile(){
    let data = localStorage.getItem("docvita-one-profile")
    if(data){
      return JSON.parse(data);
    }else{
      return null;
    }
  }

  // logoutProfile(){
    
  //   this.updateCurrentDoctor(null);
  //   this._router.navigate(['']);
  // }

  
}
