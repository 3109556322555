import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { classToPlain } from 'class-transformer';
import { IScheduleSessionForm } from 'src/app/components/schedule-appointment-modal/schedule-appointment-modal.component';
import { baseUrl, listingUrl } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SchSessionService {

  constructor(
    private http:HttpClient
  ) { }

  async createSchSession(formDetails: IScheduleSessionForm){
    try {
      const body = JSON.parse(JSON.stringify(classToPlain(formDetails)));
      const url = listingUrl + "/v1/next-session-schedule/create"
      const response = await this.http.post(url,body).toPromise()
      if(response && response['statusCode'] == 200 && response['body']['success']){
        return true;
      }
      const errMessage = response['body']['message'] || 'unknown_error'
      throw new Error(errMessage)
    } catch (error) {
      throw error;
    }
  }


  async getSchSessionFormDetails(defaultLeadId:string, providerId:string, clientId:string ){
    try {
      const body = {
        defaultLeadId, 
        providerId, 
        clientId
      }
      const url = listingUrl + '/v1/next-session-schedule/get-session-form-details'
      const response = await this.http.post(url,body).toPromise();
      // console.log(response)
      if(response && response['statusCode'] == 200 && response['body'] && response['body']['success'] && response['body']['data']){
        const { amount, currency, duration, sessionType , type, latestCountryOfVisit } = response['body']['data'];
        return { amount, currency, duration, sessionType, type, latestCountryOfVisit };
      }
      const errMessage = response['body']['message'] || 'unknown_error'
      throw new Error(errMessage)
    } catch (error) {
      throw error;
    }
  }

  checkIfAppointmentConflicting(
    doctorId: string,
    date: string,
    timeslot: string,
    leadId?: string
  ) {
    const url = baseUrl + '/v3/appointment/get-clashing-appointments'
    let body = { doctorId, date, timeslot };
    if (leadId) body['currentLeadId'] = leadId;
    return new Promise((resolve, reject) => {
      this.http
        .post(url, body)
        .toPromise()
        .then((resp) => {
          if (
            resp &&
            resp['statusCode'] == 200 &&
            resp['body']['success'] == true &&
            resp['body']['data']
          ) {
            resolve(resp['body']['data']);
          } else {
            resolve(null);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async expireScheduledSession(appointmentId:string){
    try {
      const url = listingUrl + '/v1/next-session-schedule/expire-specific-appt';
      const body = {
        appointmentId: appointmentId
      }
      const response = await this.http.post(url,body).toPromise();
      if(response && response['statusCode'] == 200 && response['body'] && response['body']['success']){
        return true; 
      }else{
        throw new Error('failed_toExpire')
      }
      
    } catch (error) {
      console.error(error);
      throw error;      
    }
  }

}
