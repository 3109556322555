import { Injectable } from '@angular/core';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../shared/firebaseHelper/firebaseHelper';
@Injectable({
  providedIn: 'root'
})
export class GetTokenService {
  constructor() { }
  public token() {
    return new Promise((resolve, reject) => {
      let unsubscribe = onAuthStateChanged(auth, (user) => {
        unsubscribe();
        if (user) {
          user
            .getIdToken(false)
            .then(idToken => {
              resolve(idToken);
            })
            .catch(function (error) {
              console.error(error);
              resolve(null);
            });
        } else {
          resolve("");
        }
      }, (err) => {
        console.error(err);
        resolve(null);
      });
    });
  }

}
