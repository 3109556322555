import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { googleIcon } from 'src/app/shared/common-assets';
import { DoctorListingProfile } from 'src/app/shared/models-ts/Actors/Doctor/DoctorListingProfile';
import { Lead } from 'src/app/shared/models-ts/Entities/Lead';
import { AppointmentService } from 'src/app/shared/services/appointment/appointment.service';
import { ClipboardService } from 'src/app/shared/services/clipboard/clipboard.service';
import { ProviderService } from 'src/app/shared/services/provider-service/provider.service';

@Component({
  selector: 'app-meeting-link-modal',
  templateUrl: './meeting-link-modal.component.html',
  styleUrls: ['./meeting-link-modal.component.scss'],
})
export class MeetingLinkModalComponent implements OnInit {
  title: string = 'Create Meeting Link';
  linkCreated: boolean = false;
  lead: Lead;
  providerProfile: DoctorListingProfile;
  linkToDisplay: string;
  creatingLink: boolean = false;
  meetingLinkOpts = {
    googlemeet: { src: googleIcon, label: 'Meet Link' },
    enable_googlemeet: { src: googleIcon, label: 'Authorize Meets' },
    // zoom: { src: zoomIcon, label: 'Zoom Link' },
    // enable_zoom: { src: zoomIcon, label: 'Authorize Zoom' },
  };

  constructor(
    public bsModalRef: BsModalRef,
    private _aptService: AppointmentService,
    private _toastr: ToastrService,
    private _providerService: ProviderService,
    private _clipboardService: ClipboardService
  ) {
    this.resetLinkMakerState();
    // console.log('resetLinkMakerState called');
  }

  ngOnInit(): void {}

  returnZero() {
    return 0;
  }

  async meetingLinkButtonActions(key: string) {
    try {
      switch (key) {
        case 'googlemeet': {
          if (this.lead && this.lead.id) {
            this.creatingLink = true;
            let meetlink = await this._aptService.createGoogleMeeting(
              this.lead
            );
            if (meetlink) {
              this.linkToDisplay = meetlink;
              this.creatingLink = false;
              this._toastr.success('Meeting Info Updated!');
              this.bsModalRef.hide();
              // this.meetingPopup.close();
              // this.linkCreated = true;
            } else {
              this.creatingLink = false;
              // this.meetingPopup.close();
              // this.linkCreated = false;
              this._toastr.warning('Failed to create link');
            }
          } else {
            this._toastr.warning('Something went wrong. Try again.');
          }
          break;
        }

        case 'zoom': {
          if (this.lead && this.lead.id) {
            this.creatingLink = true;
            let zoomlink = await this._aptService.createZoomMeeting(this.lead);
            if (zoomlink) {
              this.linkToDisplay = zoomlink;
              this.creatingLink = false;
              this._toastr.success('Meeting Info Updated!');
              this.bsModalRef.hide();
              // this.meetingPopup.close();
              // this.linkCreated = true;
            } else {
              this.creatingLink = false;
              // this.meetingPopup.close();
              // this.linkCreated = false;
              this._toastr.warning('Failed to create link');
            }
          } else {
            this._toastr.warning('Something went wrong. Try again.');
          }
          break;
        }

        case 'enable_googlemeet': {
          let meetUrl = await this._providerService.getGoogleMeetAuthUrl(
            this.providerProfile.id
          );
          if (meetUrl) window.open(meetUrl, '_self');
          // this._router.navigate(['dashboard/integrations']);
          break;
        }

        case 'enable_zoom': {
          // this._router.navigate(['dashboard/integrations']);
          let meetUrl = await this._providerService.getZoomAuthUrl(
            this.providerProfile.id
          );
          if (meetUrl) window.open(meetUrl, '_self');
          break;
        }

        default:
          break;
      }
    } catch (error) {}
  }

  toEnable(key: string) {
    let bool = true;
    switch (key) {
      case 'googlemeet':
        if (!this.providerProfile.isGoogleAuthVerified) {
          bool = false;
        }
        break;
      case 'zoom':
        if (!this.providerProfile.isZoomAuthVerified) {
          bool = false;
        }
        break;
      case 'enable_googlemeet':
        if (this.providerProfile.isGoogleAuthVerified) {
          bool = false;
        }
        break;
      case 'enable_zoom':
        if (this.providerProfile.isZoomAuthVerified) {
          bool = false;
        }
        break;

      default:
        break;
    }
    return bool;
  }

  copyTheLink() {
    if (this.linkToDisplay) {
      this.copyTxt(this.linkToDisplay);
    }
  }

  resetLinkMakerState() {
    this.linkCreated = false;
    this.creatingLink = false;
    this.linkToDisplay = '';
  }

  copyTxt(val: string) {
    this._clipboardService.copyToClipboard(val);
  }
}
