import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { BigLoaderService } from 'src/app/shared/services/big-loader/big-loader.service';

@Component({
  selector: 'app-big-loader',
  templateUrl: './big-loader.component.html',
  styleUrls: ['./big-loader.component.scss'],
})
export class BigLoaderComponent implements OnInit, OnDestroy {
  constructor(
    private _bigLoaderService: BigLoaderService,
    private chngDec: ChangeDetectorRef
  ) {}
  subscription: Subscription;
  spinnerText: string;
  showLoader: boolean = false;
  ngOnInit(): void {
    // this.initLoader();
    this.subscription = this._bigLoaderService.loaderSubject.subscribe(
      (data) => {
        // console.log('Sub called ', data, new Date());
        if (data && data['bool']) {
          this.showLoader = data['bool'];
          if (data['text']) {
            this.spinnerText = data['text'];
          } else {
            this.spinnerText = null;
          }
        } else {
          this.spinnerText = null;
          this.showLoader = false;
        }
        // this.chngDec.detectChanges();;
      },
      (err) => {
        this.spinnerText = null;
        this.showLoader = false;
      }
    );
  }
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  // initLoader() {
  //   this.showLoader = this._bigLoaderService.loaderState.bool;
  //   this.spinnerText = this._bigLoaderService.loaderState.text;
  //   console.log('Init loader');
  // }
}
