import { Injectable } from '@angular/core';
import * as elasticsearch from 'elasticsearch-browser';
import { environment as env } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class ElasticService {
  auth = env.elasticSearchAPIKey.length > 0 ? env.elasticSearchAPIKey : null;
  client = new elasticsearch.Client({
    host: env.elasticSearchUrl,
    httpAuth: this.auth,
  });
  constructor() {}
}
