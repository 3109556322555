import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DoctorListingProfile } from 'src/app/shared/models-ts/Actors/Doctor/DoctorListingProfile';
import { ProviderDataService } from 'src/app/shared/services/provider-service/provider-data.service';
import { ProviderService } from 'src/app/shared/services/provider-service/provider.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit,OnDestroy {

  constructor(
    private _providerDataService:ProviderDataService,
    private _providerService:ProviderService,
    private _route:ActivatedRoute,
    private _router:Router,
  ) { }

  subscription:Subscription;
  signInSub:Subscription;
  providerProfile:DoctorListingProfile = new DoctorListingProfile();

  ngOnInit(): void {
    this.setProfileSub();
  }
  ngOnDestroy(): void {
    if(this.subscription){
      this.subscription.unsubscribe();
    }
  }
  setProfileSub(){
    this.subscription = this._providerDataService.currentSelection.subscribe(data=>{
      if(data!=null&&data['provider']){
        this.providerProfile = data['provider'];
      }else{
      }
    },err=>{
      console.log(err)
    })
  }


}

