import { Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import * as dayjs from 'dayjs';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { DoctorListingProfile } from 'src/app/shared/models-ts/Actors/Doctor/DoctorListingProfile';
import { Lead } from 'src/app/shared/models-ts/Entities/Lead';
import { SchSessionPaymentTerms } from 'src/app/shared/models-ts/Entities/SchSession/SchSession';
import { AppointmentType, ServiceTypes } from 'src/app/shared/models-ts/Enums/Enums';
import {
  CountrywiseFeeCalc,
  Currency,
} from 'src/app/shared/models-ts/Helpers/countrywiseFeeCalc';
import { DayjsHelper } from 'src/app/shared/models-ts/Helpers/dayjsHelper';
import { ProviderDataService } from 'src/app/shared/services/provider-service/provider-data.service';
import { SchSessionService } from 'src/app/shared/services/sch-session/sch-session.service';

export interface IScheduleSessionForm {
  amount: number;
  currency: Currency;
  duration: number;
  inviteTime: string;
  inviteDate: string;
  clientId: string;
  providerId: string;
  paymentTerms: SchSessionPaymentTerms;
  expiresOn: number;
  sessionType: ServiceTypes;
  type: AppointmentType;
  practiceId: string;
  clientName: string;
  providerName: string;
  updatedById: string;
  updatedByName: string;
}

@Component({
  selector: 'schedule-appointment-modal',
  templateUrl: './schedule-appointment-modal.component.html',
  styleUrls: ['./schedule-appointment-modal.component.scss'],
})
export class ScheduleAppointmentModalComponent implements OnInit, OnDestroy {
  modalRef: BsModalRef;
  subscription: Subscription;
  providerProfile: DoctorListingProfile = new DoctorListingProfile();

  clientId: string;
  clientName: string;

  sessionTypeOptions = Object.keys(ServiceTypes);
  modeOptions: AppointmentType[] = [];
  paymentTermOptions = Object.keys(SchSessionPaymentTerms);
  currencyOptions = Object.keys(Currency);

  durationOptions = [10, 50, 60];
  amountOptions = [];

  scheduleSessionform: IScheduleSessionForm = null;
  customExpiryDate: string;
  customExpiryTime: string;

  showAvailableTimeslots: boolean = true;
  allowTimeEdit: boolean = false;

  modalLoader: boolean = false;
  dayjsHelper = new DayjsHelper();
  dayjs = this.dayjsHelper.getDayjs();

  loadingFormDetails: boolean = false;

  timeConflictErr: string = '';

  expiryTimeConflictErr: string = '';

  formSuccess: boolean = false;

  clientLatestCountry: string;

  today = this.dayjsHelper.todayYYYYMMDD()

  constructor(
    private modalService: BsModalService,
    private _providerDataService: ProviderDataService,
    private schSessionService: SchSessionService,
    private toastrService: ToastrService,
    private _ngZone: NgZone
  ) {}

  ngOnInit(): void {
    this.setProfileSub();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  setProfileSub() {
    this.subscription = this._providerDataService.currentSelection.subscribe(
      (data) => {
        if (data != null && data['provider']) {
          this.providerProfile = data['provider'];
          this.modeOptions = [AppointmentType.online];
          if(this.providerProfile.isAcceptingInPersonBookingsOnDocVita()) {
            this.modeOptions.push(AppointmentType.inPerson);
          }
          this.standardNatInNatPricing();
        } else {
          //error manage
        }
      },
      (err) => {
        //error manage
      }
    );
  }

  initSessionForm() {
    this.formSuccess = false;
    this.scheduleSessionform = {
      amount: null,
      // amount: 20,
      currency: Currency.INR,
      duration: this.providerProfile?.appointmentDuration,
      inviteTime: '',
      // inviteTime: '16:00',
      inviteDate: this.dayjsHelper.addDaysToYYYYMMDD(
        this.dayjsHelper.todayYYYYMMDD(),
        7
      ),
      clientId: this.clientId,
      providerId: this.providerProfile?.id,
      paymentTerms: SchSessionPaymentTerms.within_48_hours,
      // expiresOn: new Date().getTime(),
      expiresOn: null,
      sessionType: ServiceTypes.individual,
      type: AppointmentType.online,
      practiceId: null,
      clientName: this.clientName,
      providerName: this.providerProfile?.name,
      updatedById: this.providerProfile?.id,
      updatedByName: this.providerProfile?.name,
    };
    
    this.setExpiryTime();
    this.showAvailableTimeslots = true;
    this.allowTimeEdit = false;
  }

  setMode() {
    if(this.scheduleSessionform.type == AppointmentType.inPerson) {
      this.scheduleSessionform.practiceId = this.providerProfile.associatedPractices.keys().next().value;
    } else {
      this.scheduleSessionform.practiceId = null;
    }
  }

  

  @ViewChild('scheduleAppointmentModal') scheduleAppointmentModal;
  openModal(clientId: string, clientName: string, currentLeadId: string) {
    if (!clientId || !clientName) {
      return;
    }
    this.clientId = clientId;
    this.clientName = clientName;
    this.initSessionForm();
    this.initFormFieldsFromOnlineConfig(currentLeadId);
    let modalOptions: ModalOptions = {
      backdrop : 'static',
      keyboard : false
    };
    this.modalRef = this.modalService.show(this.scheduleAppointmentModal, modalOptions);
  }

  closeModal() {
    this.modalRef.hide();
  }

  async submitForm() {
    try {

      this.timeConflictErr = '';
      this.expiryTimeConflictErr = ''
      this.formSuccess = false;
      this.modalLoader = true;

      this.validateExpiryDateTime();
      this.validateInviteDateTime();

      if (!this.isFormvalid()) {
        // error
        this.modalLoader = false;
        return;
      }

      const timeslot =
        this.scheduleSessionform['inviteTime'] +
        '-' +
        this.dayjsHelper.endTimeForStartTimeHHmm(
          this.scheduleSessionform['inviteTime'],
          this.scheduleSessionform['duration']
        );
      const checkForClashingTime =
        await this.schSessionService.checkIfAppointmentConflicting(
          this.providerProfile?.id,
          this.scheduleSessionform['inviteDate'],
          timeslot
        );
      if (checkForClashingTime && checkForClashingTime['hasConflicts']) {
        this.timeConflictErr = 'Times overlap with a blocked slot';
        this.modalLoader = false;
        return;
      }

      this.setExpiryTime();
      const response = await this.schSessionService.createSchSession(
        this.scheduleSessionform
      );
      this.formSuccess = true;
      this.modalLoader = false;
      this.toastrService.success('Successfully Scheduled');
      // this.closeModal();
    } catch (error) {
      //error
      this.formSuccess = false;
      this.toastrService.error('Failed to schedule appointment');
      this.modalLoader = false;
    }
  }

  validateInviteDateTime(){
    const currentTime = new Date().getTime();
    const inviteDateTime = this.dayjsHelper.YYYYMMDDHHmmToUnix(
      this.scheduleSessionform.inviteDate,
      this.scheduleSessionform.inviteTime||'23:59' 
    );

    // console.log(inviteDateTime ,this.scheduleSessionform.inviteDate,this.scheduleSessionform.inviteTime, currentTime,inviteDateTime < currentTime)
    
    if (inviteDateTime < currentTime) {
      this.timeConflictErr = 'Needs Attention: The selected date/time is in the past';
      // this.modalLoader = false;
      return;
    }else{
      this.timeConflictErr = ''
    }
  }

  validateExpiryDateTime(){
    const currentTime = new Date().getTime();

    const expiryDateTime = this.dayjsHelper.YYYYMMDDHHmmToUnix(
      this.customExpiryDate,
      this.customExpiryTime||'23:59'
    );
    const inviteDateTime = this.dayjsHelper.YYYYMMDDHHmmToUnix(
      this.scheduleSessionform.inviteDate,
      this.scheduleSessionform.inviteTime||'23:59' 
    );
    
    if (expiryDateTime < currentTime) {
      this.expiryTimeConflictErr = 'We cannot select this time as this has already passed';
      // this.modalLoader = false;
      return;
    }else if(expiryDateTime > inviteDateTime){
      this.expiryTimeConflictErr = 'Attention: Per our policy, the client needs to make payment before the session start time';
      // this.modalLoader = false;
      return;
    }else{
      this.expiryTimeConflictErr = ''
    }
  }


  get isPastDate(){
    const currentTime = new Date().getTime();
    const inviteDateTime = this.dayjsHelper.YYYYMMDDHHmmToUnix(
      this.scheduleSessionform.inviteDate,
      '23:59' 
    );
    
    if (inviteDateTime < currentTime) {
      return true;
    }else{
      return false;
    }
    
  }

  setExpiryTime() {
    const dayjsHelper = new DayjsHelper();
    const dayjs = dayjsHelper.getDayjs();
    const paymentTerm = this.scheduleSessionform['paymentTerms'];
    const inviteDateTimeInUnix = dayjsHelper.YYYYMMDDHHmmToUnix(
      this.scheduleSessionform['inviteDate'],
      this.scheduleSessionform['inviteTime']
    );
    const currentTime = dayjsHelper.currentTime();

    switch (paymentTerm) {
      case SchSessionPaymentTerms.before_24_hours: {
        const timeDiff = dayjs(inviteDateTimeInUnix).subtract(24, 'hours');
        this.customExpiryDate = timeDiff.format('YYYY-MM-DD');
        this.customExpiryTime = timeDiff.format('HH:mm');
        const expiryOn = new Date(this.customExpiryDate + ' ' + this.customExpiryTime).getTime();
        this.scheduleSessionform.expiresOn = expiryOn;
        return;
      }
      case SchSessionPaymentTerms.before_48_hours: {
        const timeDiff = dayjs(inviteDateTimeInUnix).subtract(48, 'hours');
        this.customExpiryDate = timeDiff.format('YYYY-MM-DD');
        this.customExpiryTime = timeDiff.format('HH:mm');
        const expiryOn = new Date(this.customExpiryDate + ' ' + this.customExpiryTime).getTime();
        this.scheduleSessionform.expiresOn = expiryOn;
        return;
      }
      case SchSessionPaymentTerms.within_24_hours: {
        const timeDiff = dayjs(currentTime).add(24, 'hours');
        this.customExpiryDate = timeDiff.format('YYYY-MM-DD');
        this.customExpiryTime = this.getNextRoundedTime(timeDiff.format('HH:mm'));
        const expiryOn = new Date(this.customExpiryDate + ' ' + this.customExpiryTime).getTime();
        this.scheduleSessionform.expiresOn = expiryOn;
        return;
      }
      case SchSessionPaymentTerms.within_48_hours: {
        const timeDiff = dayjs(currentTime).add(48, 'hours');
        this.customExpiryDate = timeDiff.format('YYYY-MM-DD');
        this.customExpiryTime = this.getNextRoundedTime(timeDiff.format('HH:mm'));
        const expiryOn = new Date(this.customExpiryDate + ' ' + this.customExpiryTime).getTime();
        this.scheduleSessionform.expiresOn = expiryOn;
        return;
      }
      case SchSessionPaymentTerms.custom: {
        const expiryOn = new Date(this.customExpiryDate + ' ' + this.customExpiryTime).getTime();
        this.scheduleSessionform.expiresOn = expiryOn;
        return;
      }
      default: {
        return;
      }
    }
  }

  isFormvalid() {
    const validDateLength = new DayjsHelper().todayYYYYMMDD().length;
    
    if(!(this.expiryTimeConflictErr == null || this.expiryTimeConflictErr.length == 0)) {
      this.toastrService.error(this.expiryTimeConflictErr);
      return false;
    } else if (
      this.scheduleSessionform.amount &&
      this.scheduleSessionform.currency &&
      this.scheduleSessionform.inviteDate &&
      this.scheduleSessionform.inviteDate.length >= validDateLength &&
      this.scheduleSessionform.inviteTime &&
      this.scheduleSessionform.inviteTime.length > 4 &&
      this.scheduleSessionform.duration &&
      this.scheduleSessionform.duration > 0 &&
      this.scheduleSessionform.paymentTerms
    ) {
      if (
        this.scheduleSessionform.paymentTerms ==
          SchSessionPaymentTerms.custom &&
        (!this.customExpiryDate || !this.customExpiryTime)
      ) {
        this.toastrService.error('Form missing details');
        return false;
      }
      return true;
    }
    this.toastrService.error('Form missing details');
    return false;
  }

  selectSlot(slot) {
    this.scheduleSessionform.inviteTime = slot['startTime'];
    this.validateInviteDateTime()
    this.updateExpiryTimeForWithin24hr();
    this.toggleProviderTimeslots(false);
    this.allowTimeEdit = false;
  }

  editTimeManually() {
    this.toggleProviderTimeslots(false);
    this.allowTimeEdit = true;
    this.scheduleSessionform.inviteTime = null;
  }

  toggleProviderTimeslots(bool: boolean) {
    this.showAvailableTimeslots = bool;
  }

  appointmentDateScroller(direction) {
    if (direction == '+' && this.scheduleSessionform['inviteDate']) {
      this.scheduleSessionform['inviteDate'] = this.dayjs(
        new Date(this.scheduleSessionform['inviteDate'])
      )
        .add(1, 'day')
        .format('YYYY-MM-DD');
    } else if (direction == '-' && this.scheduleSessionform['inviteDate']) {
      this.scheduleSessionform['inviteDate'] = this.dayjs(
        new Date(this.scheduleSessionform['inviteDate'])
      )
        .subtract(1, 'day')
        .format('YYYY-MM-DD');
    } else {
      //cool
    }
  }

  dashedToTitleCase(text: string) {
    let _textArr = text.split('_');
    let final = _textArr.map((v) => this.textToTitleCase(v)).join(' ');
    return final;
  }

  textToTitleCase(text: string) {
    return text[0].toUpperCase() + text.slice(1);
  }

  getlanguageForPayterms(item: SchSessionPaymentTerms) {
    switch (item) {
      case SchSessionPaymentTerms.before_24_hours: {
        return '24 hours before appointment';
      }
      case SchSessionPaymentTerms.before_48_hours: {
        return '48 hours before appointment';
      }
      default: {
        return this.dashedToTitleCase(item);
      }
    }
  }

  setDurations(minutes: number) {
    this.scheduleSessionform['duration'] = +minutes;
  }

  standardNatInNatPricing() {
    this.amountOptions = [];
    if (
      this.providerProfile &&
      this.providerProfile.onlineConsultationConfig &&
      this.providerProfile.onlineConsultationConfig.fee != null
    ) {
      const masterSpecialization =
        this.providerProfile.getMasterSpecialization();
      const fee = this.providerProfile.onlineConsultationConfig.fee;
      const in_fees = new CountrywiseFeeCalc().fee(
        fee,
        'IN',
        masterSpecialization
      );
      const us_fees = new CountrywiseFeeCalc().fee(
        fee,
        'US',
        masterSpecialization
      );
      const ae_fees = new CountrywiseFeeCalc().fee(
        fee,
        'AE',
        masterSpecialization
      );
      const othr_fees = new CountrywiseFeeCalc().fee(
        fee,
        null,
        masterSpecialization
      );
      // let fees_string = '(IN) ' + in_fees.amount + ' ' + in_fees.unit + ' • (US/CA) ' + us_fees.amount + ' ' + us_fees.unit + ' • (Other) ' + othr_fees.amount + ' ' + othr_fees.unit
      this.amountOptions = [in_fees, us_fees, ae_fees, othr_fees];
    }
  }

  currencyToSymbol(unit: Currency) {
    return new CountrywiseFeeCalc().currencyToSymbol(unit);
  }

  setAmountAndCurrencyFromOptions(amt: { amount: number; unit: Currency }) {
    this.scheduleSessionform['amount'] = amt.amount;
    this.scheduleSessionform['currency'] = amt.unit;
  }

  getFeeForSessionType() {
    const servicewiseFeeMap =
      this.providerProfile.onlineConsultationConfig.servicewiseFeesMap;
    const serviceFee = servicewiseFeeMap.get(
      this.scheduleSessionform.sessionType
    );
    if (serviceFee && serviceFee.isActive) {
      const fee = serviceFee.fee;
      const currency = serviceFee.currency;
      const cc = this.clientLatestCountry ? this.clientLatestCountry : new CountrywiseFeeCalc().currencyToCountry(currency);
      const countryWiseFee = new CountrywiseFeeCalc().fee(fee,cc,this.providerProfile.getMasterSpecialization());
      this.scheduleSessionform.amount = countryWiseFee.amount;
      this.scheduleSessionform.currency = countryWiseFee.unit;
    }
  }

  async initFormFieldsFromOnlineConfig(defaultLeadId: string) {
    this._ngZone.run(async () => {
      try {
        this.loadingFormDetails = true;
        const formDetails =
          await this.schSessionService.getSchSessionFormDetails(
            defaultLeadId,
            this.providerProfile?.id,
            this.clientId
          );
        this.scheduleSessionform.amount = formDetails.amount;
        this.scheduleSessionform.currency = formDetails.currency;
        this.scheduleSessionform.duration = formDetails.duration;
        this.scheduleSessionform.sessionType = formDetails.sessionType;
        this.scheduleSessionform.type = formDetails.type;
        this.loadingFormDetails = false;
        this.setMode();
      } catch (error) {
        //do nothing
        this.loadingFormDetails = false;
      }
    });
  }

  getTimeInHHmma(timestring: string) {
    return this.dayjsHelper.HHmmtohhmma(timestring);
  }

  getNextRoundedTime(timestring: string){
    const dateObj = new Date(`2000-01-01T${timestring}:00`);
    if (dateObj.getMinutes() > 0 && dateObj.getMinutes() < 15) {
      dateObj.setMinutes(15);
    }else if(dateObj.getMinutes() > 15 && dateObj.getMinutes() < 30){
      dateObj.setMinutes(30);
    }else if(dateObj.getMinutes() > 30 && dateObj.getMinutes() < 45){
      dateObj.setMinutes(45);
    }else if(dateObj.getMinutes() > 45 && dateObj.getMinutes() < 59){
      dateObj.setMinutes(59);
    }
    dateObj.setSeconds(0);

    const roundedTimeString = dateObj.toLocaleTimeString("en-US", {hour12: false});

    return roundedTimeString.slice(0,5);
  }

  getUnixInReadableDateTime(unixTime:number){
    return this.dayjsHelper.unixToDDMMMYYYY(unixTime) + ', ' + this.dayjsHelper.unixTohhmma(unixTime)
  }

  handleChangePayterms(){
    this.setExpiryTime();
    this.validateExpiryDateTime();
  }

  updateExpiryTimeForWithin24hr(){
    /*
    - less than 24 hours, and more than 2 hours → Deadline is 2 hours by default
    - < 2 hours - 1 hour → 30 mins
    - < 1 hour - >20 mins → 10 mins
    - < 20 mins → equal to appointment start time
     */
    
    const currentTime = this.dayjsHelper.currentTime();

    const time24HrsFromNow = this.dayjs(currentTime).add(24,'hours').valueOf();
    const time2HrsFromNow = this.dayjs(currentTime).add(2,'hours').valueOf();
    const time1HrsFromNow = this.dayjs(currentTime).add(1,'hours').valueOf();
    const time20MinsFromNow = this.dayjs(currentTime).add(20,'minutes').valueOf();

    if(this.scheduleSessionform.inviteDate &&
       this.scheduleSessionform.inviteTime &&
      /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(this.scheduleSessionform.inviteTime)
    ){
      const inviteDateTimeInUnix = this.dayjsHelper.YYYYMMDDHHmmToUnix(
        this.scheduleSessionform['inviteDate'],
        this.scheduleSessionform['inviteTime']
      );
      if((inviteDateTimeInUnix < time24HrsFromNow) && (inviteDateTimeInUnix > time2HrsFromNow)){

        this.scheduleSessionform.paymentTerms = SchSessionPaymentTerms.custom;
        const inviteDateTimeMinus2hrs = this.dayjs(inviteDateTimeInUnix).subtract(2,'hours').valueOf()
        this.customExpiryDate = this.dayjsHelper.unixToYYYYMMDD(inviteDateTimeMinus2hrs)
        this.customExpiryTime = this.dayjsHelper.unixToHHmm(inviteDateTimeMinus2hrs)

      }else if((inviteDateTimeInUnix < time2HrsFromNow ) && (inviteDateTimeInUnix > time1HrsFromNow)){

        this.scheduleSessionform.paymentTerms = SchSessionPaymentTerms.custom;
        const inviteDateTimeMinus30Mins = this.dayjs(inviteDateTimeInUnix).subtract(30,'minutes').valueOf()
        this.customExpiryDate = this.dayjsHelper.unixToYYYYMMDD(inviteDateTimeMinus30Mins)
        this.customExpiryTime = this.dayjsHelper.unixToHHmm(inviteDateTimeMinus30Mins)

      }else if((inviteDateTimeInUnix < time1HrsFromNow ) && (inviteDateTimeInUnix > time20MinsFromNow)){

        this.scheduleSessionform.paymentTerms = SchSessionPaymentTerms.custom;
        const inviteDateTimeMinus10Mins = this.dayjs(inviteDateTimeInUnix).subtract(10,'minutes').valueOf()
        this.customExpiryDate = this.dayjsHelper.unixToYYYYMMDD(inviteDateTimeMinus10Mins)
        this.customExpiryTime = this.dayjsHelper.unixToHHmm(inviteDateTimeMinus10Mins)

      }else if(inviteDateTimeInUnix < time20MinsFromNow){

        this.scheduleSessionform.paymentTerms = SchSessionPaymentTerms.custom;
        this.customExpiryDate = this.scheduleSessionform['inviteDate'].slice()
        this.customExpiryTime = this.scheduleSessionform['inviteTime'].slice()

      }else{

        this.scheduleSessionform.paymentTerms = SchSessionPaymentTerms.within_48_hours;
        this.setExpiryTime();
        
      }
    }  
  }
}
