import { Injectable } from '@angular/core';
import { PatientService } from '../shared/services/patient-service/patient.service';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../shared/firebaseHelper/firebaseHelper';
import { plainToClass } from 'class-transformer';
import { User } from '../shared/models-ts/Actors/User/User';
@Injectable({
  providedIn: 'root'
})
export class PatientsService {

  constructor(private _patientService: PatientService ) { }

  async fetchClientProfile(clientId: string) {
    try {
      const resp = await this._patientService.searchPatient(
        clientId
      );
      if(resp && resp.length > 0) {
        return resp[0];
      } else {
        throw new Error("no client found");
      }
    } catch(error) {
      console.error(error);
    }
  }
  async fetchClientProfileFromDB(clientId: string) {
    try {
      const docRef = doc(db, 'docvita_patient_profiles', clientId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists) {
        return plainToClass(User,docSnap.data())
      } else {
        throw new Error("no client found");
      }
    } catch(error) {
      console.error(error);
    }
  }
}
