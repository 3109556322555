<!-- <ng-container > -->
<div class="top-section">
  <!-- <button class="back-btn" (click)="gotoMenu()">
                  <span class="material-icons back-btn-icon">
                  arrow_back_ios
                  </span>
              </button> -->
  <div class="route-label">Integrations</div>
</div>
<div class="main-wrapper">
  <div class="integration-container">
    <div
      class="integration-option"
      [id]="option.key"
      *ngFor="let option of integrationOptions | keyvalue: returnZero"
    >
      <div class="option-img-badge">
        <img [src]="option.value.imageUrl" alt="icon" class="option-img" />
      </div>
      <div class="option-label-desc">
        <div class="option-label">
          {{ option.value.label }}
        </div>
        <div class="option-description">
          {{ option.value.description }}
        </div>
      </div>
      <div class="option-connect-container">
        <ng-container *ngIf="!option.value.comingSoon">
          <div
            class="option-userinfo"
            *ngIf="
              option.value.linkedAccount &&
              !(loaderId[option.key] && loaderId[option.key] == true)
            "
          >
            <div class="option-userinfo-title pb-1">Connected with</div>
            <div class="option-userinfo-content">
              {{ option.value.linkedAccount }}
            </div>
          </div>
          <sa-spinner-circular
            [enabled]="loaderId[option.key] && loaderId[option.key] == true"
            size="25"
            color="#5952FF"
            secondaryColor="rgba(24, 24, 24, 0.09)"
            style="margin-right: 0.5rem"
          ></sa-spinner-circular>
          <!-- style="font-weight: 400" -->
          <button
            class="btn btn-primary"
            style="font-weight: 500"
            *ngIf="!option.value.isConnected"
            (click)="integrationOps(option.key, 'connect')"
            [disabled]="loaderId == option.key"
          >
            Integrate Now
          </button>
          <button
            class="btn btn-sm btn-outline-danger"
            style="font-weight: 400; border-width: 0.5px"
            *ngIf="option.value.isConnected"
            (click)="integrationOps(option.key, 'remove')"
          >
            Disconnect
          </button>
        </ng-container>

        <span style="color: #777" *ngIf="option.value.comingSoon">
          Coming Soon
        </span>
        <!-- <button
          class="btn btn-sm btn-outline-primary"
          style="font-weight: 400"
          *ngIf="option.value.comingSoon"
        >
          Coming Soon
        </button> -->
      </div>

    </div>
  </div>
</div>

<!-- </ng-container> -->
