<div class="navbar-container">
    
    <img
    src="https://s3.ap-south-1.amazonaws.com/docvita.com/docvita_logo_transparent_bg.png"
    alt="logo"
    class="docvita-logo mr-auto"
    />
    <button class="burger" (click)="toggleDropDown()">
        <span class="material-icons">
            menu
        </span>
        <div class="dropdown-card" *ngIf="dropDown">
            <div class="dropdown-option" style="color: rgb(255, 92, 92);" (click)="logout()">
                <span class="material-icons" style="font-size: 0.8em;">
                    logout
                </span>
                <span style="font-size: 0.8em;margin-left: 0.5rem;">Logout</span>
            </div>
        </div>
    </button>
    <!-- <div class="options" (click)="toggleDropDown()">
        <div class="profile-badge">
            <span class="material-icons" style="font-size: 0.7em;">
                person
            </span>
        </div>
        <div style="font-size: 0.7em;margin-left: 0.5rem;">{{providerProfile?providerProfile.fullName():'xxxxx'}}</div>
        
    </div> -->

</div>