import { Injectable, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ProviderService } from '../provider-service/provider.service';
import { plainToClass } from 'class-transformer';
import { DoctorListingProfile } from '../../models-ts/Actors/Doctor/DoctorListingProfile';
import { ProviderDataService } from '../provider-service/provider-data.service';
import {
  signInWithCustomToken,
  onAuthStateChanged,
  signOut,
} from 'firebase/auth';
import { auth } from '../../firebaseHelper/firebaseHelper';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { DVTrackService } from 'src/app/services/dv-track/dvtrack.service';

@Injectable({
  providedIn: 'root',
})
export class SigninService {
  constructor(
    private _http: HttpClient,
    private _providerService: ProviderService,
    private _providerDataService: ProviderDataService,
    private _router: Router,
    private _dvTrackService: DVTrackService,
    private _ngZone: NgZone
  ) {}

  async sendOTP(number?: string, email?: string) {
    try {
      let body = {};
      if (number) body['number'] = number;
      if (email) body['email'] = email;
      let otpResponse = await this._http
        .post(environment['send-provider-otp'], body)
        .toPromise();
      if (otpResponse) {
        // console.log(otpResponse)
        return otpResponse;
      } else {
        return null;
      }
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  async verifyOTP(otp: string, contact: any) {
    try {
      let body = { otp, contact, passToken: true };
      let verifyResponse = await this._http
        .post(environment['verify-provider-otp'], body)
        .toPromise();
      if (verifyResponse) {
        // console.log(verifyResponse)
        return verifyResponse;
      } else {
        return null;
      }
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  async resendOTP(contact: any) {
    try {
      let body = { contact };
      let resendResponse = await this._http
        .post(environment['resend-provider-otp'], body)
        .toPromise();
      if (resendResponse) {
        // console.log(resendResponse);
        return resendResponse;
      } else {
        return null;
      }
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  async updateProfileAndSource(providerId: string, authSource: string) {
    try {
      if (!providerId && !authSource) {
        return false;
      }
      console.log('trying to fetch profile');
      let providerProfile = await this._providerService.fetchProviderProfile(
        providerId
      );
      if (providerProfile) {
        console.log('got the profile');
        let profile = plainToClass(DoctorListingProfile, providerProfile);
        this._providerDataService.updateCurrentDoctor(profile);
        this._providerDataService.setLocalProfile(
          this.localProfileBody(profile.id, authSource)
        );
        return true;
      } else {
        console.log('Failed to fetch provider profile.');
        return false;
      }
    } catch (error) {
      console.log('Failed to fetch provider profile.');
      return false;
    }
  }

  async signInWithToken(token) {
    try {
      let creds = await signInWithCustomToken(auth, token);
      if (creds) {
        // console.log(creds)
        let providerProfileData = await this._providerService.fetchProviderProfile(
          creds.user.uid
        );
        let properties: any = {};
        if(providerProfileData) {
          let profile = plainToClass(DoctorListingProfile, providerProfileData);
          properties["name"] = profile.fullName();
          properties["masterSpecialization"] = profile.getMasterSpecializationDisplay();
          properties["gender"] = profile.gender;
        } 
        this._dvTrackService.identify(creds.user.uid,properties);
        return creds.user.uid;
      } else {
        return null;
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  signInStatus() {
    return new Observable<string>((observer) => {
      let _ = onAuthStateChanged(
        auth,
        (user) => {
          this._ngZone.run(() => {
            if (user) {
              observer.next(user.uid);
            } else {
              observer.error('signed out');
            }
          })
          
        },
        (err) => {
          this._ngZone.run(() => {
            observer.error('signed out');
          })
        }
      );
      return {
        unsubscribe() {
          _();
        },
      };
    });
  }

  recheckAuth() {
    return new Promise((resolve, reject) => {
      let url = environment['reauth-check'];
      this._http
        .post(url,{})
        .toPromise()
        .then((resp: any) => {
          if(resp && resp.statusCode == 200 && resp.body && resp.body.success) {
            resolve(resp);
          } else {
            reject();
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async signOut() {
    try {
      let _ = await signOut(auth);
      this._dvTrackService.reset();
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  private jsonFormat(obj) {
    return JSON.parse(JSON.stringify(obj));
  }
  private localProfileBody(providerId: string, authSource: string) {
    if (providerId && authSource) {
      let response = {
        providerId: providerId,
        auth: {
          source: authSource,
          lastSignIn: new Date().getTime(),
        },
      };
      return response;
    } else {
      return {};
    }
  }
}
