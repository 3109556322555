import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class ClipboardService {
  constructor(private _toastr: ToastrService) {}
  copyToClipboard(val: string) {
    if (val) {
      navigator.clipboard.writeText(val);
      this._toastr.success('Copied!');
    } else {
      this._toastr.warning('Failed to copy.');
    }
  }
}
