import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatePickerModalComponent } from './reusable/date-picker-modal/date-picker-modal.component';
import { BigLoaderComponent } from './reusable/big-loader/big-loader.component';
import { StringTrimPipe } from './pipes/string-trim.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { SpinnerCircularModule } from 'spinners-angular/spinner-circular';
import { SpinnerInfinityModule } from 'spinners-angular/spinner-infinity';
import { ProviderAvailableSlotsComponent } from './reusable/provider-available-slots/provider-available-slots.component';
import { ProviderDailyAppointmentsComponent } from './reusable/provider-daily-appointments/provider-daily-appointments.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
@NgModule({
  declarations: [
    DatePickerModalComponent,
    BigLoaderComponent,
    StringTrimPipe,
    ProviderAvailableSlotsComponent,
    ProviderDailyAppointmentsComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SpinnerCircularModule,
    BsDatepickerModule,
    TooltipModule,
    SpinnerInfinityModule,
    TooltipModule
  ],
  exports: [
    DatePickerModalComponent,
    BigLoaderComponent,
    StringTrimPipe,
    ProviderAvailableSlotsComponent,
    ProviderDailyAppointmentsComponent,
  ],
})
export class SharedModule {}
