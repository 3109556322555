import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringTrim',
})
export class StringTrimPipe implements PipeTransform {
  transform(value: string, length: number): string {
    if (value && value.length > 0) {
      if (value.length > length) {
        return value.slice(0, length) + '...';
      } else {
        return value;
      }
    }
    return '';
  }
}
