<ng-template #scheduleAppointmentModal>
    <div class="modal-header border-0" *ngIf="!formSuccess">
        <h5 class="modal-title pull-left">Schedule Next Session <span class="fw-normal">for</span> {{clientName}}</h5>
    </div>

    <div class="modal-body d-flex flex-column px-4">

        <!-- loader  -->
        <div class="d-flex align-items-center justify-content-center py-5" *ngIf="loadingFormDetails">
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <ng-container *ngIf="formSuccess && !loadingFormDetails">
            <div class="w-100 mb-4 d-flex flex-column align-items-center justify-content-center">
                <img src="assets/icons/success_verified.svg" class="success-img">
                <h5 class="modal-title pull-left">Session Scheduled <span class="fw-normal">for</span> {{clientName}}
                </h5>
            </div>
        </ng-container>

        <!-- form  -->
        <ng-container *ngIf="!loadingFormDetails">
            <div class="info-row">
                <label for="session-type-select" class="form-label">Type of Session</label>
                <ng-container *ngIf="!formSuccess">
                    <select class="form-select" (change)="getFeeForSessionType()"
                        [(ngModel)]="scheduleSessionform['sessionType']">
                        <option [value]="item" *ngFor="let item of sessionTypeOptions">{{ item|titlecase }}</option>
                    </select>
                </ng-container>
                <ng-container *ngIf="formSuccess">
                    <span class="info pe-2">{{scheduleSessionform['sessionType'] | titlecase }}</span>
                </ng-container>

            </div>
            <div class="info-row" *ngIf="modeOptions.length > 1">
                <label for="appt-mode-select" class="form-label">Session Mode</label>
                <ng-container *ngIf="!formSuccess">
                    <select class="form-select" (change)="setMode()"
                        [(ngModel)]="scheduleSessionform['type']">
                        <option [value]="item" *ngFor="let item of modeOptions">{{ item|titlecase }}</option>
                    </select>
                </ng-container>
                <ng-container *ngIf="formSuccess">
                    <span class="info pe-2">{{scheduleSessionform['type'] | titlecase }}</span>
                </ng-container>
            </div>
            <div class="info-row" [ngClass]="{'border-0 mb-0': !formSuccess}">
                <label for="duration-input" class="form-label">Duration (mins)</label>

                <span class="d-flex align-items-center justify-content-end">
                    <ng-container *ngIf="!formSuccess">
                        <input type="number" class="info form-control pe-1" id="duration-input" placeholder="In Minutes"
                            [(ngModel)]="scheduleSessionform['duration']">
                    </ng-container>
                    <ng-container *ngIf="formSuccess">
                        <span class="info pe-1">
                            {{scheduleSessionform['duration']}}
                        </span>
                    </ng-container>
                    <span class="me-2" style="font-weight:500;color:#1d1d1f ;">minutes</span>
                </span>

            </div>
            <div class="info-row justify-content-end" *ngIf="!formSuccess">
                <div class="btn-group">
                    <button class="btn btn-sm btn-outline-secondary"
                        [ngClass]="{'active': scheduleSessionform['duration'] == min}"
                        *ngFor="let min of durationOptions" (click)="setDurations(min)">{{min}} mins</button>
                </div>
            </div>
            <div class="info-row" [ngClass]="{'border-0 mb-0': !formSuccess}">
                <label for="fees-input" class="form-label">Fee</label>
                <div class="info d-flex w-100 gap-2 justify-content-end">
                    <ng-container *ngIf="!formSuccess">
                        <input type="number" class="form-control w-50" id="fees-input" placeholder="Fee"
                            [(ngModel)]="scheduleSessionform['amount']">
                        <select class="form-select" style="width: fit-content;"
                            [(ngModel)]="scheduleSessionform['currency']">
                            <option [value]="item" *ngFor="let item of currencyOptions">{{item + ' (' +
                                currencyToSymbol(item) + ')'}}</option>
                        </select>
                    </ng-container>
                    <ng-container *ngIf="formSuccess">
                        <span class="pe-1">{{scheduleSessionform['amount']}}</span>
                        <span class="pe-2">{{scheduleSessionform['currency']}}</span>
                    </ng-container>
                </div>
            </div>
            <div class="info-row justify-content-end" *ngIf="!formSuccess">
                <div class="btn-group">
                    <button class="btn btn-sm btn-outline-secondary" (click)="setAmountAndCurrencyFromOptions(amt)"
                        *ngFor="let amt of amountOptions">{{currencyToSymbol(amt.unit)+amt.amount}}</button>
                </div>
            </div>
            <div class="info-row">
                <label for="session-type-select" class="form-label">Payment Terms</label>
                <ng-container *ngIf="!formSuccess">
                    <select class="form-select" (change)="handleChangePayterms()" [(ngModel)]="scheduleSessionform['paymentTerms']">
                        <option [value]="item" *ngFor="let item of paymentTermOptions">{{getlanguageForPayterms(item)}}
                        </option>
                    </select>
                </ng-container>
                <ng-container *ngIf="formSuccess">
                    <span class="info pe-1">
                        {{getlanguageForPayterms(scheduleSessionform['paymentTerms'])}}
                    </span>
                </ng-container>
            </div>
            <div class="info-row" [ngClass]="{'border-0': expiryTimeConflictErr}" *ngIf="scheduleSessionform['paymentTerms'] == 'custom' && !formSuccess">
                <label for="date-input" class="form-label">Payment By</label>
                <div class="d-flex w-100 gap-2">
                    <input type="date" class="form-control px-1" (change)="validateExpiryDateTime();" [min]="today"
                        [(ngModel)]="customExpiryDate">
                    <input type="time" class="form-control px-1"
                        (change)="validateExpiryDateTime()"
                        [(ngModel)]="customExpiryTime">
                </div>
                
            </div>
            <div class="info-row" *ngIf="expiryTimeConflictErr">
                <p class="text-light my-1 bg-danger p-2 w-100" style="font-size: 14px;font-weight: 600;text-align: center;" >
                    {{expiryTimeConflictErr}}
                </p>
            </div>
            
            <div class="info-row" *ngIf="formSuccess">
                <label for="date-input" class="form-label">Payment By</label>
                <span class="info pe-1">
                    {{getUnixInReadableDateTime(scheduleSessionform['expiresOn'])}}
                </span>
            </div>

            <div class="info-row align-items-start">
                <label for="date-input" class="form-label" [ngClass]="{'mt-2': !formSuccess}"
                    style="width: fit-content;">Date</label>
                <div class="d-flex w-100 flex-column align-items-end justify-content-end">
                    <ng-container *ngIf="!formSuccess">
                        <input type="date" class="form-control px-1" [min]="today" style="width: 180px; background-position: right;"
                            placeholder="Appt. Date" (change)="validateInviteDateTime();updateExpiryTimeForWithin24hr();" [(ngModel)]="scheduleSessionform['inviteDate']">
                            <ng-container *ngIf="!isPastDate">
                            <provider-daily-appointments [date]="scheduleSessionform['inviteDate']"
                                [providerId]="providerProfile?.id"></provider-daily-appointments>
                            </ng-container>
                        
                    </ng-container>
                    <ng-container *ngIf="formSuccess">
                        <span class="info pe-2">{{scheduleSessionform['inviteDate'] | date:'EEE, dd MMM YYYY'}}</span>
                    </ng-container>
                    <!-- daily appts. go here  -->
                </div>

            </div>

            <div class="info-row align-items-start" [ngClass]="{'border-0 mb-0': !formSuccess}">
                <label for="date-input" class="form-label" [ngClass]="{'mt-2': !formSuccess}"
                    style="width: 30%">Time</label>
                <div *ngIf="!formSuccess" class="info d-flex flex-column align-items-end" style="width: 70%;">
                    <ng-container *ngIf="!showAvailableTimeslots || scheduleSessionform['inviteTime']">
                        <input type="time" class="form-control px-1" style="width: 180px;" (change)="validateInviteDateTime();updateExpiryTimeForWithin24hr();" placeholder="Appt. Time"
                            [(ngModel)]="scheduleSessionform['inviteTime']">
                    </ng-container>
                    
                    <ng-container *ngIf="showAvailableTimeslots">
                        <provider-available-slots [mode]="scheduleSessionform['type']" [doctorProfile]="providerProfile"
                            [date]="scheduleSessionform['inviteDate']" (selectedSlot)="selectSlot($event)"
                            [disableContainer]="true"></provider-available-slots>
                        <button class="btn btn-outline-primary border-0 btn-sm mt-1 width" (click)="editTimeManually()">
                            Choose custom time
                        </button>
                    </ng-container>
                    <button class="btn btn-outline-primary border-0 btn-sm mt-1"
                        *ngIf="!showAvailableTimeslots && providerProfile && providerProfile.id"
                        (click)="toggleProviderTimeslots(true)">
                        Show available times
                    </button>
                </div>
                <ng-container *ngIf="formSuccess">
                    <div class="info d-flex justify-content-end" style="width: 70%;">
                        <span class="pe-2">{{ getTimeInHHmma(scheduleSessionform['inviteTime']) }}</span>
                    </div>
                </ng-container>
            </div>
            <p class="text-light my-1 bg-danger p-2" style="font-size: 14px;font-weight: 600;text-align: center;" *ngIf="timeConflictErr">
                {{timeConflictErr}}
            </p>
        </ng-container>

        <!-- success screen  -->
        <!-- <ng-container *ngIf="formSuccess && !loadingFormDetails">
            <ng-container *ngTemplateOutlet="successScreen"></ng-container>
        </ng-container> -->


    </div>

    <div class="modal-footer border-0" *ngIf="!loadingFormDetails">
        <ng-container *ngIf="!formSuccess">
            <button type="button" class="btn btn-secondary" [disabled]="modalLoader" (click)="closeModal()">
                Cancel
            </button>
            <button type="button" class="btn btn-primary" [disabled]="modalLoader" (click)="submitForm()">
                <span class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"
                    *ngIf="modalLoader"></span>
                Confirm & Schedule
            </button>
        </ng-container>
        <ng-container *ngIf="formSuccess">
            <div class="w-100 d-flex align-items-center justify-content-center">
                <button type="button" class="btn btn-primary" (click)="closeModal()">
                    Looks good!
                </button>
            </div>
        </ng-container>
    </div>

</ng-template>

<ng-template #formDateScroller>
    <div class="d-flex ms-1">
        <button class="btn btn-outline-primary btn-sm border-0 ps-0 pe-0" (click)="appointmentDateScroller('-')">
            <i class="feather icon-chevron-left" style="font-size: 16px"></i>
        </button>
        <button class="btn btn-outline-primary btn-sm border-0 ps-0 pe-0" (click)="appointmentDateScroller('+')">
            <i class="feather icon-chevron-right" style="font-size: 16px"></i>
        </button>
    </div>
</ng-template>