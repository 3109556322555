<p class="mb-0" style="color: #6e6e73;text-align: right; word-wrap: break-word;width: 100%;">
    <span *ngIf="appointments?.length == 0 && !loader">
        On {{date | date:'EEE, dd MMM'}}, we do not have any confirmed or scheduled appointments
    </span>

    <span *ngIf="loader || appointments?.length>0">
        On {{date | date:'EEE, dd MMM'}}:
    </span>
    <br>
    <span class="spinner-border spinner-border-sm" *ngIf="loader"></span>
    <span class="d-flex flex-wrap justify-content-end gap-1" *ngIf="appointments?.length>0 && !loader">
        <span class="text-nowrap d-flex align-items-center" *ngFor="let item of appointments;let i = index;let last = last;">
            <em class="me-1">{{item?.patientName}}</em>
            -
            {{item?.startTime}} 
            <ng-container *ngIf="item?.isScheduled">
                [
                <span tooltip="scheduled" class="material-icons pointer" style="font-size: 14px;">
                    question_mark
                </span>
                ]
            </ng-container>
            
            <ng-container *ngIf="!item?.isScheduled">
                [
                <span tooltip="confirmed" class="material-icons pointer" style="font-size: 14px;">
                    done
                </span>
                ]
            </ng-container>
            
            {{last? '':', '}}
        </span>
    </span>
</p>