import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from "@angular/core";
import {
  DVBaseStepComponent,
  collapseAnimation,
} from "../DVBaseStep.component";
import { DvStepInfo, DvStepService } from "../service/dv-step.service";
import { IconPath } from "../../assets/icon-path.constants";

@Component({
  selector: "app-dv-step",
  templateUrl: "./dv-step.component.html",
  styleUrls: ["./dv-step.component.scss", "./../dv-step-styles.scss"],
  animations: [collapseAnimation],
})
export class DvStepComponent extends DVBaseStepComponent implements OnInit {
  greenCheckIconPath = IconPath.greenCheckIcon;
  chevronLeftIconPath = IconPath.chevronLeftIcon;

  @Input() title: string;
  @Input() set stepNumber(val: number) {
    this.thisStepNumber = val;
    this.updateCollapsedState();
  }
  @Input() showChangeButton: boolean = false;

  @Input() replaceCardWithBottomBorder: boolean = false;

  @ContentChild("completedCollapsedContent")
  completedCollapsedContent: TemplateRef<any>;

  constructor(protected dvStepService: DvStepService) {
    super(dvStepService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }
}
