import { ServiceTypes } from "../../Enums/Enums";
import { AppointmentInvite } from "../AppointmentInvite/AppointmentInvite";
import { Expose, Type } from "class-transformer";
import { TransactionSource } from "../../Enums/pay";
import { ContactNumber } from "../../Actors/Person/ContactNumber";
import { BooleanAction } from "../../Protocols/BooleanAction";
import { AmountWithCurrency } from "../../Protocols/AmountWithCurrency";

export class SchSession {

    @Expose() id: string;
    @Expose() clientId: string;
    @Expose() clientName: string;
    @Expose() providerId: string;
    @Expose() providerName: string;

    @Expose() inviteDate: string;
    @Expose() inviteTime: string;

    @Type(() => BooleanAction)
    @Expose() created: BooleanAction;

    @Type(() => BooleanAction)
    @Expose() updated: BooleanAction;

    @Expose() duration: number; //In mins
    @Expose() amount: AmountWithCurrency;
    @Expose() providerDisplayAmount: AmountWithCurrency;

    @Expose() status: SchSessionStatus;
    @Expose() type: string; //online / inPerson
    @Expose() practiceId: string;
    
    @Expose() paymentTerms: SchSessionPaymentTerms;
    @Expose() expiresOn: number;

    @Expose() reminderSent: BooleanAction;
    @Expose() stopReminder: boolean;

    @Expose() sessionType: ServiceTypes;

    @Type(() => SchSessionPaymentDetails)
    @Expose() paymentDetails: SchSessionPaymentDetails = new SchSessionPaymentDetails();
}

export enum SchSessionStatus {
    created = "created",
    expired = "expired",  
    booked = "booked",  
    cancelled = "cancelled"
}

export class SchSessionPaymentDetails {
    @Expose() source: TransactionSource;
    @Expose() status: SchSessionPaymentStatus;
    @Expose() paymentId: string;
    @Expose() paymentDoneOn: number;
    @Expose() payerEmail: string; //Optional: If in case payer is different than client - we can capture their email here
    @Type(() => ContactNumber)
    @Expose() payerContactNumber: ContactNumber; //Optional: If in case payer is different than client - we can capture their contact number here
}

export enum SchSessionPaymentStatus {
    pending = "pending",
    paid = "paid",
}

export enum SchSessionPaymentTerms {
    within_24_hours = "within_24_hours",
    within_48_hours = "within_48_hours",
    before_24_hours = "before_24_hours",
    before_48_hours = "before_48_hours",
    custom = "custom"
}
