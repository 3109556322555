import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

export class DvStepInfo {
  currentStep: number;
  finalProcessLoader: boolean = false;

  constructor(currentStep: number, finalProcessLoader: boolean) {
    this.currentStep = currentStep;
    this.finalProcessLoader = finalProcessLoader;
  }
}

@Injectable({
  providedIn: "root",
})
export class DvStepService {
  private stepInfo = new DvStepInfo(-1, false);
  private currentStep = new BehaviorSubject<DvStepInfo>(this.stepInfo);
  currentStep$ = this.currentStep.asObservable();

  // stepComplete$ = new BehaviorSubject<number>(-1);

  constructor() {}

  // stepIsComplete(stepNumber: number) {
    // this.stepComplete$.next(stepNumber);
    // this.currentStep$.next(stepNumber + 1);
  // }

  updateStepInfo(step: number, finalProcessLoader: boolean = false) {
    this.stepInfo.currentStep = step;
    this.stepInfo.finalProcessLoader = finalProcessLoader;
    // console.log("Updating current step to ", step);
    this.currentStep.next(this.stepInfo);
  }

  updateFinalProcessLoaderState(finalProcessLoader: boolean) {
    this.stepInfo.finalProcessLoader = finalProcessLoader;
    // console.log("Updating current step to ", step);
    this.currentStep.next(this.stepInfo);
  }
}
