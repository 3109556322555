import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DoctorListingProfile } from 'src/app/shared/models-ts/Actors/Doctor/DoctorListingProfile';
import { ProviderDataService } from 'src/app/shared/services/provider-service/provider-data.service';
import { SigninService } from 'src/app/shared/services/signin-service/signin.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit,OnDestroy {
  providerProfile:DoctorListingProfile = new DoctorListingProfile();
  subscription:Subscription;
  dropDown:boolean = false;
  constructor(
    private _providerDataService:ProviderDataService,
    private _signinService:SigninService,
    private _router:Router
  ) { }

  ngOnInit(): void {
    this.setProfileSub();
  }
  ngOnDestroy(): void {
    if(this.subscription){
      this.subscription.unsubscribe();
    }
  }
  setProfileSub(){
    this.subscription = this._providerDataService.currentSelection.subscribe(data=>{
      if(data!=null&&data['provider']){
        this.providerProfile = data['provider'];
      }else{
      }
    },err=>{
      console.log(err)
    })
  }

  toggleDropDown(){
    this.dropDown = !this.dropDown;
  }

  async logout(){
    try {
      let state = await this._signinService.signOut();
      if(state){
        this._router.navigate([''])
        this._providerDataService.updateCurrentDoctor(null);
      }else{
        console.log("Failed to signout");
      }
    } catch (error) {
      console.log("Failed to signout");
    }
    
  }
}
