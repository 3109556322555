import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { DoctorListingProfile } from 'src/app/shared/models-ts/Actors/Doctor/DoctorListingProfile';
import { Lead } from 'src/app/shared/models-ts/Entities/Lead';
import { AppointmentService } from 'src/app/shared/services/appointment/appointment.service';
import { ProviderDataService } from 'src/app/shared/services/provider-service/provider-data.service';
import { debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';
import * as dayjs from 'dayjs';
import { onSnapshot } from 'firebase/firestore';
import { plainToClass } from 'class-transformer';
import { SessionStorageService } from 'src/app/shared/services/session-storage-service/session-storage.service';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { DatePickerModalComponent } from '../../../shared/reusable/date-picker-modal/date-picker-modal.component';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { AppointmentType } from 'src/app/shared/models-ts/Enums/Enums';

@Component({
  selector: 'app-appointments',
  templateUrl: './appointments.component.html',
  styleUrls: ['./appointments.component.scss'],
})
export class AppointmentsComponent implements OnInit, OnDestroy {
  @ViewChild('datePickerRef')
  dateRangePicker: DatePickerModalComponent;

  @ViewChild('dp') datePickerInp;

  bsConfig: Partial<BsDatepickerConfig> = {
    containerClass: 'theme-dark-blue',
    customTodayClass: 'custom-datepicker',
  };
  dateRange: any = {
    start: null,
    end: null,
  };
  inlineRangeValue: Date[] = [new Date(), new Date()];
  listingOptionSub = new Subject();
  apptSubscription;
  subscription: Subscription;
  providerProfile: DoctorListingProfile = new DoctorListingProfile();
  startDate: string;
  endDate: string;
  sortOrder: string = 'bookingDate';
  searchTerm;
  searchSubject = new Subject();
  leads: Lead[] = [];
  isLoadingLeads: boolean = false;
  topPanelOptions = {
    upcoming: 'Today + Future',
    // pending: 'Pending',
    previous: 'Past',
    // date_range: 'Date Range',
  };
  selectedTopPanelOption: string = 'upcoming';
  currentlyDroppedLead: string = '';
  finalLeads = [];
  pageState = {};
  constructor(
    private _aptService: AppointmentService,
    private _providerDataService: ProviderDataService,
    private _detChanges: ChangeDetectorRef,
    private _router: Router,
    private _sessionService: SessionStorageService,
    private _modalService: BsModalService
  ) {}
  pageConfig = {
    itemsPerPage: 5,
    currentPage: 1,
  };

  ngOnInit(): void {
    this.setProfileSub();
    // if (this.providerProfile && this.providerProfile.id) {
    this.listingOptionSub.pipe(debounceTime(700)).subscribe((val) => {
      this.populateAppointments();
      // this._detChanges.detectChanges();
    });
    this.searchSubject.pipe(debounceTime(800)).subscribe(() => {
      this.finalLeadsFilter();
    });
    // }
  }
  ngAfterViewInit() {
    // this._detChanges.detectChanges();
    // this.listingOptionSub.next('start');
  }
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.apptSubscription) {
      this.apptSubscription();
    }
  }

  populateAppointments() {
    this.resetPagination();
    // this.setPageState('date_range', this.dateRange);
    // console.log('called populate');
    let dateSpread = this.getDateRangeISO(this.dateRange);
    this.leads = [];
    if (
      this.selectedTopPanelOption == 'date_range' &&
      !this.dateRange['start'] &&
      !this.dateRange['end']
    ) {
      this.finalLeads = [];
      return;
    }
    let queryDoc = this._aptService.getAppointmentByDate(
      this.providerProfile.id,
      dateSpread.start,
      dateSpread.end,
      this.sortOrder,
      this.selectedTopPanelOption
    );
    this.isLoadingLeads = true;
    this.apptSubscription = onSnapshot(
      queryDoc,
      (snap) => {
        // console.log('top pan opt: ', this.selectedTopPanelOption);
        this.isLoadingLeads = true;
        // console.log('called snap');
        if (!snap.empty) {
          let leads: Lead[] = [];
          snap.forEach((data) => {
            let _ = plainToClass(Lead, data.data());
            _.id = data.id;
            
            // TODO: remove this (testing only)
            // _.consultationInfo.locationGoogleMapsLink = 'https://google.com'
            // _.consultationInfo.locationId = this.providerProfile.associatedPractices.keys().next().value;
            // _.consultationInfo.type = AppointmentType.inPerson
            // TODO END

            leads.push(_);
          });
          if (leads) {
            // this.sortLeadsByOrder(leads);
            this.leads = leads;
            this.arrangeLeadsDateWise();
          }
          this.isLoadingLeads = false;
          // console.log(this.leads);
        } else {
          this.leads = [];
          this.finalLeads = [];
          this.isLoadingLeads = false;
        }
        this._detChanges.detectChanges();
      },
      (err) => {
        this.leads = [];
        this.isLoadingLeads = false;
        console.log(err);
      }
    );
  }

  sortLeadsByOrder(leads: Lead[]) {
    if (this.sortOrder == 'bookingDate') {
      leads = leads.sort((a, b) => {
        if (a && b && a.consultationInfo && b.consultationInfo) {
          return (
            a.consultationInfo.date + a.consultationInfo.timeslot
          ).localeCompare(
            b.consultationInfo.date + b.consultationInfo.timeslot
          );
        }
        return 0;
      });
    } else {
      leads = leads.sort((a, b) => {
        if (a && b && a.createdOn && b.createdOn) {
          return b.createdOn - a.createdOn;
        }
        return 0;
      });
    }
  }

  arrangeLeadsDateWise() {
    this.finalLeads = [];
    if (this.sortOrder == 'bookingDate') {
      let dates: string[] = [];
      this.leads.forEach((lead) => {
        if (!dates.includes(lead.consultationInfo.date)) {
          dates.push(lead.consultationInfo.date);
        }
      });
      if (
        this.selectedTopPanelOption == 'upcoming' ||
        this.selectedTopPanelOption == 'pending'
      ) {
        dates.sort((a, b) => {
          return a.localeCompare(b);
        });
      }
      dates.forEach((date) => {
        let leadsForDate = this.leads.filter(
          (da) => da && da.consultationInfo && da.consultationInfo.date == date
        );
        this.sortLeadsByTimeslot(leadsForDate);
        this.finalLeads.push({
          date: date,
          leads: leadsForDate,
        });
      });
    } else {
      let createdOnDates = new Set(
        this.leads.map(
          (da) => new Date(da.createdOn).toISOString().split('T')[0]
        )
      );
      createdOnDates.forEach((date) => {
        let leadsForDate = this.leads.filter(
          (da) =>
            da && new Date(da.createdOn).toISOString().split('T')[0] == date
        );
        this.sortLeadsByTimeslot(leadsForDate);
        this.finalLeads.push({
          date: date,
          leads: leadsForDate,
        });
      });
    }
  }

  sortLeadsByTimeslot(leads: Lead[]) {
    leads = leads.sort((a, b) => {
      if (a && b && a.consultationInfo && b.consultationInfo) {
        return a.consultationInfo.timeslot.localeCompare(
          b.consultationInfo.timeslot
        );
      }
      return 0;
    });
  }

  setProfileSub() {
    this.subscription = this._providerDataService.currentSelection.subscribe(
      (data) => {
        if (data != null && data['provider']) {
          this.providerProfile = data['provider'];
          // console.log(this.providerProfile);
          // this.populateAppointments();
          this.getPageState();
          // this.changeListingOpt();
          this.populateAppointments();
        } else {
        }
        // this._detChanges.detectChanges();
      },
      (err) => {
        console.log(err);
      }
    );
  }
  leadsFilter(val?) {
    if (this.searchTerm) {
      if (!isNaN(this.searchTerm)) {
        return this.leads.filter(
          (da) =>
            da &&
            da.consultationInfo &&
            da.consultationInfo.patientPrimaryContactNumber.includes(
              this.searchTerm
            )
        );
      } else {
        return this.leads.filter(
          (da) =>
            da &&
            da.consultationInfo &&
            da.consultationInfo.patientName
              .toLowerCase()
              .includes(this.searchTerm.toLowerCase())
        );
      }
    } else {
      return this.leads;
    }
  }
  setSearchTerm() {
    this.searchSubject.next();
    this.resetPagination();
  }
  onSearchTermChange() {
    this.resetPagination();
    this.setPageState('search_term', this.searchTerm);
  }

  finalLeadsFilter() {
    if (this.searchTerm) {
      if (!isNaN(this.searchTerm)) {
        let leads = [];
        for (let item of this.finalLeads) {
          let _item = Object.assign({}, item);
          _item['leads'] = _item['leads'].filter(
            (da) =>
              da &&
              da.consultationInfo.patientPrimaryContactNumber.includes(
                this.searchTerm
              )
          );
          if (_item['leads'] && _item['leads'].length > 0) {
            leads.push(_item);
          }
        }
        this.finalLeads = leads;
      } else {
        let leads = [];
        for (let item of this.finalLeads) {
          let _item = Object.assign({}, item);
          _item['leads'] = _item['leads'].filter(
            (da) =>
              da &&
              da.consultationInfo.patientName
                .toLowerCase()
                .includes(this.searchTerm.toLowerCase())
          );
          if (_item['leads'] && _item['leads'].length > 0) {
            leads.push(_item);
          }
        }
        // console.log('Filtered leads:', leads);
        this.finalLeads = leads;
      }
    } else {
      // console.log('Leads length: ', this.leads.length);
      this.arrangeLeadsDateWise();
    }
  }
  changeListingOpt(event?) {
    this.listingOptionSub.next(this.dateRange);
  }
  //for date range modal
  setDateRange(event) {
    if (event && event['start'] && event['end']) {
      this.dateRange = event;
      this.changeListingOpt();
    }
  }
  resetPagination() {
    this.pageConfig.currentPage = 1;
  }

  private getDateRangeISO(dateRange: any): {
    start: string;
    end: string;
  } {
    let start = dayjs.tz(dateRange.start).format('YYYY-MM-DD');
    let end = dayjs.tz(dateRange.end).format('YYYY-MM-DD');
    // console.log('start date is: ', start);
    // console.log('end date is: ', end);
    return { start, end };
  }
  get displayDateRange() {
    if (this.dateRange.start && this.dateRange.end) {
      let start = dayjs.tz(this.dateRange.start).format('DD/MM/YYYY');
      let end = dayjs.tz(this.dateRange.end).format('DD/MM/YYYY');

      return '( ' + start + ' - ' + end + ' )';
    } else {
      return '';
    }
  }
  timeslotFormatter(timeslot) {
    if (timeslot) {
      let _s = this.tConvert(timeslot.split('-')[0]);
      let _e = this.tConvert(timeslot.split('-')[1]);
      return _s + ' - ' + _e;
    } else {
      return '';
    }
  }
  tConvert(time) {
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
    if (time.length > 1) {
      time = time.slice(1);
      time[5] = +time[0] < 12 ? ' am' : ' pm';
      time[0] = +time[0] % 12 || 12;
    }
    return time.join('');
  }
  updateRouteParams(params) {
    // let _params = {}
    // _params[k] = v;
    this._router.navigate([], {
      queryParams: params,
    });
  }
  returnZero() {
    return 0;
  }
  switchToTopPanelTab(key: string) {
    this.selectedTopPanelOption = key;

    switch (key) {
      case 'date_range':
        this.datePickerInp.show();
        break;

      default:
        this.clearDateRange();
        break;
    }
    this.setPageState('top_option', key);
    this.listingOptionSub.next(key);
  }

  setPageState(k, v) {
    if (k && v) {
      this.pageState[k] = v;
      this._sessionService.setItem('appointment_page', this.pageState);
    }
  }
  getPageState() {
    let _state = this._sessionService.getItem('appointment_page');
    if (_state) {
      if (_state['top_option'])
        this.selectedTopPanelOption = _state['top_option'];
      if (_state['date_range']) {
        let _daterange = _state['date_range'];
        if (
          _daterange['start'] &&
          _daterange['end'] &&
          this.selectedTopPanelOption == 'date_range'
        ) {
          this.dateRange.start = new Date(_daterange['start']);
          this.dateRange.end = new Date(_daterange['end']);
          this.initDateRangePicker();
        } else {
          this.clearDateRange();
        }
      }
      if (_state['search_term']) this.searchTerm = _state['search_term'];

      this.pageState = _state;
    }
  }
  setLeadToDrop(leadId: string) {
    this.currentlyDroppedLead = leadId;
  }
  clearDateRange() {
    this.dateRange = {};
    this.inlineRangeValue = [];
  }
  initDateRangePicker() {
    if (this.dateRange) {
      this.inlineRangeValue = [this.dateRange['start'], this.dateRange['end']];
    } else {
      this.inlineRangeValue = [];
    }
  }
  setInlineDateRange() {
    let dateRange = {
      start: this.inlineRangeValue[0],
      end: this.inlineRangeValue[1],
    };
    this.dateRange = dateRange;
    this.setPageState('date_range', this.dateRange);
    this.changeListingOpt();
  }
  capitalize(str: string) {
    if (str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    } else {
      return '';
    }
  }
}
