import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DvStepComponent } from "./baseStep/dv-step/dv-step.component";
import { RouterModule } from "@angular/router";
import { DvCustomRadioComponent } from "./components/dv-custom-radio/dv-custom-radio.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

@NgModule({
  declarations: [DvStepComponent, DvCustomRadioComponent],
  imports: [CommonModule, RouterModule, FormsModule, ReactiveFormsModule],
  exports: [DvStepComponent, DvCustomRadioComponent],
  providers: [],
})
export class SuperPowersModule {}
