import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  constructor(
    private _router:Router,
    private _route:ActivatedRoute
  ) { }

  ngOnInit(): void {
  }
  gotoMenu(){
    this._router.navigate(['../'],{relativeTo:this._route})
  }
}
