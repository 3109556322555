import { Component, Input, OnInit } from '@angular/core';
import { AppointmentService } from '../../services/appointment/appointment.service';
import { DayjsHelper } from '../../models-ts/Helpers/dayjsHelper';

@Component({
  selector: 'provider-daily-appointments',
  templateUrl: './provider-daily-appointments.component.html',
  styleUrls: ['./provider-daily-appointments.component.scss'],
})
export class ProviderDailyAppointmentsComponent implements OnInit {
  _date: string;
  @Input() set date(val: string) {
    const isYYYYMMDD =
      /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/.test(val);
    if (val && isYYYYMMDD) {
      this._date = val;
      this.getAppointmentsForDay();
    }
  }
  get date() {
    return this._date;
  }

  _providerId: string;
  @Input() set providerId(val: string) {
    if (val) {
      this._providerId = val;
      this.getAppointmentsForDay();
    }
  }
  get providerId() {
    return this._providerId;
  }

  dayjsHelper: DayjsHelper = new DayjsHelper();

  appointments: {
    patientName: string;
    startTime: string;
    isScheduled: boolean;
  }[] = [];

  loader: boolean = false;

  constructor(private _appointmentService: AppointmentService) {}

  ngOnInit(): void {}

  async getAppointmentsForDay() {
    try {
      this.loader = true;
      this.appointments = [];
      const leads = await this._appointmentService.getLeadsForAppointmentByDate(
        this.providerId,
        this.date
      );
      leads.forEach((v) => {
        if (
          v?.consultationInfo?.status == 'CONFIRMED' ||
          v?.consultationInfo?.status == 'SCHEDULED'
        ) {
          this.appointments.push({
            patientName: v?.consultationInfo?.patientName,
            startTime: this.dayjsHelper.HHmmtohhmma(
              v?.consultationInfo?.getStartTime()
            ),
            isScheduled: v?.isScheduledAppointment,
          });
        }
      });
      this.loader = false;
    } catch (error) {
      this.appointments = [];
      this.loader = false;
    }
  }
}
