import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-cancel-request-modal',
  templateUrl: './cancel-request-modal.component.html',
  styleUrls: ['./cancel-request-modal.component.scss']
})
export class CancelRequestModalComponent implements OnInit {

  title: string = "Request Sent!";
  closeBtnName: string = "OK";

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit(): void {
  }

}
