import { Component, ElementRef, HostListener, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PlatformCheckService } from './services/platform-check/platform-check.service';
import { SigninService } from './shared/services/signin-service/signin.service';
import { Subscription } from 'rxjs';
import { ProviderDataService } from './shared/services/provider-service/provider-data.service';
import { ProviderService } from './shared/services/provider-service/provider.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BigLoaderService } from './shared/services/big-loader/big-loader.service';
import { AnalyticsService } from './services/analytics.service';
import { SessionStorageService } from './shared/services/session-storage-service/session-storage.service';
import { plainToClass } from 'class-transformer';
import { DoctorListingProfile } from './shared/models-ts/Actors/Doctor/DoctorListingProfile';
import {
  onAuthStateChanged,
} from 'firebase/auth';
import { auth } from './shared/firebaseHelper/firebaseHelper';
import { environment } from 'src/environments/environment';
import { DVTrackService } from './services/dv-track/dvtrack.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = '';
  signInSub: Subscription;
  profileSub: Subscription;
  providerProfile: DoctorListingProfile = new DoctorListingProfile();
  subscription: Subscription;
  isSignedIn: boolean = false;

  @HostListener("mouseover")
  handler = (eventData: Event) => {
    this.loadScriptsOnFirstMouseInteraction();
  }

  @HostListener("touchmove")
  touchHandler = (eventData: Event) => {
    this.loadScriptsOnFirstMouseInteraction();
  }
  @ViewChild('script') script: ElementRef;

  loadingPage:boolean = false;
  scriptsLoaded:boolean = false;

  constructor(
    private _signinService: SigninService,
    private _platformCheckService: PlatformCheckService,
    private _providerDataService: ProviderDataService,
    private _providerService: ProviderService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _bigLoaderService: BigLoaderService,
    private _analyticsService:AnalyticsService,
    private sessionStorage:SessionStorageService,
    private _zone:NgZone,
    private _dvTrackService: DVTrackService
  ) {

  }

  ngOnInit(): void {
    this._bigLoaderService.start();
    this.reauthCheck();
    onAuthStateChanged(
      auth,
      (user) => {
        this._zone.run(()=>{
          if (user && user?.uid) {
            // observer.next(user.uid);
            this._analyticsService.opened(user?.uid);
            this.isSignedIn = true;
            this.getProvider(user?.uid);
          } else {
            // observer.error('signed out');
            this.isSignedIn = false;
            this.preserveRoute()
            this.gotoHome();
          }
        })
      },
      (err) => {
        this._zone.run(()=>{
          // observer.error('signed out');
          this.isSignedIn = false;
          this.preserveRoute()
          this.gotoHome();
        })
      }
    );
  }

  loadScriptsOnFirstMouseInteraction() {
    if (this._platformCheckService.isThisClient() && !this.scriptsLoaded) {
      this.scriptsLoaded = true;
      if (environment.production && environment.analyticsTracking) {
        this.hotjarScript();
        this._dvTrackService.startTracking();
      }
    }
  }

  hotjarScript() {
    if (window.hj == null) {
      // console.log("fb loaded")
      let element = this.script.nativeElement;
      let script = document.createElement('script');
      script.type = 'text/javascript';
      script.defer = true;
      script.text = `(function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:3065708,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;
      if (element.innerHTML) {
        script.innerHTML = element.innerHTML;
      }
      let parent = element.parentElement;
      parent.parentElement.appendChild(script, parent);
    }
  }

  reauthCheck() {
    if(this._platformCheckService.isThisClient()) {
      this._signinService.recheckAuth()
      .then(()=>{
        //Nothing
      }).catch(err=>{
        console.error(err);
        // this._bigLoaderService.stop();
        this._signinService.signOut();
      })
    }
  }


  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.signInSub) {
      this.signInSub.unsubscribe();
    }
    if (this.profileSub) {
      this.profileSub.unsubscribe();
    }
  }

  async getProvider(id?) {
    this._bigLoaderService.start();
    if (this._providerDataService.getCurrentDoctor() == null) {
      try {
        if (this.profileSub) {
          this.profileSub.unsubscribe();
        }
        this.profileSub = this._providerService.fetchLiveProviderProfile(id).subscribe(val=>{
          if (val) {
            let _p = plainToClass(DoctorListingProfile, val);
            this._providerDataService.updateCurrentDoctor(_p);
            this._bigLoaderService.stop();
          } else {
            //failed to fetch profile
            this.gotoHome();
          }
        },err=>{
          console.log(err);
          this.gotoHome();
        })
        // let profile = await this._providerService.fetchProviderProfile(id);
        
      } catch (error) {
        //something went wrong
        this.gotoHome();
        console.log(error);
      }
    } else {
      this._bigLoaderService.stop();
      // this.setProfileSub();
    }
    
  }


  gotoHome() {
    const arr = window.location.pathname.split("/");
    if(arr.includes("availability") && arr.length == 3) {
      //Nothing for now
      console.log(arr);
    } else {
      this._router.navigate(['']);
      this._bigLoaderService.stop();
    }
    
  }

  preserveRoute(){
    const actualPath = window.location.pathname+window.location.search;
    this.sessionStorage.setItem('one_restore_path',actualPath)
  }
}
