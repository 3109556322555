import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { auth, db } from '../../firebaseHelper/firebaseHelper';
import { environment as env } from 'src/environments/environment';
import { User } from '../../models-ts/Actors/User/User';
import { ElasticService } from '../elastic-service/elastic.service';

@Injectable({
  providedIn: 'root',
})
export class PatientService {
  constructor(
    private _elasticService: ElasticService,
    private _http: HttpClient
  ) {}

  async searchPatient(searchTerm) {
    try {
      if (isNaN(searchTerm) && searchTerm != '') {
        let q = {
          query: {
            multi_match: {
              query: searchTerm,
              fields: ['name', 'docvitaId'],
              type: 'phrase_prefix',
              operator: 'OR',
            },
          },
          sort: [
            {
              'name.keyword': 'asc',
            },
            {
              createdOn: 'desc',
            },
          ],
        };
        let results = await this._elasticService.client.search({
          index: 'india_md_patient_text_index_3_alias',
          type: '_doc',
          filterPath: ['hits.hits._source', 'hits.total', '_scroll_id'],
          body: q,
          size: 250,
        });
        if (results && results.hits.hits != null) {
          let profiles: User[] = [];
          results.hits.hits.forEach((val) => {
            let json = val._source;
            profiles.push(plainToClass(User, json));
          });
          return profiles;
        } else {
          return [];
        }
      } else if (!isNaN(searchTerm) && searchTerm != '') {
        let profiles: User[] = [];
        let profilesRef = collection(db, 'docvita_patient_profiles');
        let q = query(
          profilesRef,
          where('primaryContactNumber.number', '==', searchTerm)
        );
        let resProfiles = await getDocs(q);
        if (!resProfiles.empty) {
          resProfiles.forEach((docs) => {
            profiles.push(plainToClass(User, docs.data()));
          });
          return profiles;
        } else {
          return [];
        }
      } else {
        return [];
      }
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  async createPatient(formData) {
    try {
      if (formData) {
        formData['dateOfBirth'] = '';
        let url = env['create-patient-profile'];
        let response = await this._http.post(url, formData).toPromise();
        if (
          response &&
          response['statusCode'] == 200 &&
          response['body'] &&
          response['body']['success'] == true
        ) {
          return response['body']['docvitaId'];
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }
}
