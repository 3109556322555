import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Lead } from 'src/app/shared/models-ts/Entities/Lead';

@Component({
  selector: 'app-view-client-info-modal',
  templateUrl: './view-client-info-modal.component.html',
  styleUrls: ['./view-client-info-modal.component.scss']
})
export class ViewClientInfoModalComponent implements OnInit {

  title: string;
  description: string;
  type: ClientInfoEnum;
  _lead:Lead;

  set lead (val: Lead) {
    this._lead = val;
    if(this.type == ClientInfoEnum.email) {
      this.description = val.consultationInfo.patientPrimaryEmail;
      this.title = "Client's email";
    } else if( this.type == ClientInfoEnum.contactNumber) {
      this.description = "+"+val.consultationInfo.patientPrimaryContactNumber;
      this.title = "Client's contact number";
    }
  }

  get lead(): Lead {
    return this._lead;
  }

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit(): void {
  }

}

export enum ClientInfoEnum{
  email="email",
  contactNumber="contactNumber"
}
