import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { plainToClass, classToPlain } from 'class-transformer';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { DoctorListingProfile } from 'src/app/shared/models-ts/Actors/Doctor/DoctorListingProfile';
import { ConsultationPlatformConfig } from 'src/app/shared/models-ts/Entities/ConsultationPlatformConfig';
import { OnlineMeetingDetails } from 'src/app/shared/models-ts/Entities/OnlineMeetingDetails';
import { OnlineConsultationPlatform } from 'src/app/shared/models-ts/Enums/Enums';
import { ProviderDataService } from 'src/app/shared/services/provider-service/provider-data.service';
import { ProviderService } from 'src/app/shared/services/provider-service/provider.service';
import { zoomIcon, googleIcon } from 'src/app/shared/common-assets';
class IntegrationOptions {
  id: string;
  label: string;
  description: string;
  imageUrl: string;
  isConnected: boolean;
  linkedAccount: string;
  comingSoon: boolean;
  constructor(
    id: string,
    label: string,
    description: string,
    imageUrl: string,
    isConnected: boolean,
    linkedAccount: string,
    comingSoon: boolean
  ) {
    this.id = id;
    this.label = label;
    this.description = description;
    this.imageUrl = imageUrl;
    this.isConnected = isConnected;
    this.linkedAccount = linkedAccount;
    this.comingSoon = comingSoon;
  }
}

@Component({
  selector: 'app-integrations',
  templateUrl: './integrations.component.html',
  styleUrls: ['./integrations.component.scss'],
})
export class IntegrationsComponent implements OnInit, OnDestroy {
  integrationOptions: any = {};
  providerProfile: DoctorListingProfile = new DoctorListingProfile();
  subscription: Subscription;
  loaderId: any = {};
  configToggledId: any = {};
  onlineConsultationPlatformConfig = {};
  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _providerDataService: ProviderDataService,
    private _providerService: ProviderService,
    private _toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.setProfileSub();
  }
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  setProfileSub() {
    this.subscription = this._providerDataService.currentSelection.subscribe(
      (data) => {
        if (data != null && data['provider']) {
          this.providerProfile = data['provider'];
          this.populateIntegrationOptions();
          // this.initOnlineConsultPlatformConfig();
        } else {
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
  populateIntegrationOptions() {
    this.integrationOptions = {
      googlemeet: new IntegrationOptions(
        'googlemeet',
        'Google Meet',
        'Create Google Meet links automaticaly for every appointment booking.',
        googleIcon,
        false,
        null,
        false
      ),
      zoom: new IntegrationOptions(
        'zoom',
        'Zoom',
        'Enable dynamic meeting links creation via zoom.',
        zoomIcon,
        false,
        null,
        true
      ),
    };

    for (let intergration in this.integrationOptions) {
      this.getIntegrationStates(intergration);
    }
  }

  toggleConfig(integrationId: string) {
    if (integrationId && !this.toggleConfig[integrationId]) {
      this.toggleConfig[integrationId] = true;
    } else {
      this.toggleConfig[integrationId] = false;
    }
  }

  private updateIntegrationConnectionStatus(integrationId: string, status) {
    if (status && status == true) {
      status = true;
    } else {
      status = false;
    }
    if (this.integrationOptions[integrationId]) {
      this.integrationOptions[integrationId].isConnected = status;
    }
  }

  private resetIntegrationState(integrationId: string) {
    this.integrationOptions[integrationId]['isConnected'] = false;
    this.integrationOptions[integrationId]['linkedAccount'] = null;
    if (integrationId == 'googlemeet') {
      this.providerProfile.isGoogleAuthVerified = false;
    } else if (integrationId == 'zoom') {
      this.providerProfile.isZoomAuthVerified = false;
    }
  }

  returnZero() {
    return 0;
  }

  async integrationOps(integrationId: string, eventType: string) {
    // console.log('event: ', integrationId, ' ', eventType);
    try {
      this.loaderId[integrationId] = true;
      switch (integrationId) {
        case 'googlemeet': {
          if (eventType == 'connect') {
            let meetUrl = await this._providerService.getGoogleMeetAuthUrl(
              this.providerProfile.id
            );
            if (meetUrl) {
              this.redirectToAuthPage(meetUrl);
              this.loaderId[integrationId] = false;
            } else {
              this.loaderId[integrationId] = false;
              console.log('Failed to connect.');
            }
          } else if (eventType == 'remove') {
            let deauth = await this._providerService.removeGoogleMeetOauth(
              this.providerProfile.id
            );
            if (deauth) {
              this.loaderId[integrationId] = false;
              this.resetIntegrationState(integrationId);
            } else {
              this.loaderId[integrationId] = false;
            }
          }
          break;
        }
        case 'zoom': {
          if (eventType == 'connect') {
            let meetUrl = await this._providerService.getZoomAuthUrl(
              this.providerProfile.id
            );
            if (meetUrl) {
              this.redirectToAuthPage(meetUrl);
              this.loaderId[integrationId] = false;
            } else {
              this.loaderId[integrationId] = false;
              console.log('Failed to connect.');
            }
          } else if (eventType == 'remove') {
            let deauth = await this._providerService.removeZoomOauth(
              this.providerProfile.id
            );
            if (deauth) {
              this.loaderId[integrationId] = false;
              this.resetIntegrationState(integrationId);
            } else {
              this.loaderId[integrationId] = false;
            }
          }

          break;
        }
        default:
          this.loaderId[integrationId] = false; 
          break;
      }
    } catch (error) {
      this.loaderId[integrationId] = false;
      console.log(error);
    }
  }

  async getIntegrationStates(integrationId: string) {
    try {
      switch (integrationId) {
        case 'googlemeet': {
          this.updateIntegrationConnectionStatus(
            'googlemeet',
            this.providerProfile.isGoogleAuthVerified
          );
          if (
            this.providerProfile &&
            this.providerProfile.isGoogleAuthVerified
          ) {
            this.loaderId[integrationId] = true;
            let userInfo = await this._providerService.getGoogleUserInfo(
              this.providerProfile.id
            );
            if (userInfo && userInfo['email']) {
              this.integrationOptions[integrationId]['linkedAccount'] =
                userInfo['email'];
              this.loaderId[integrationId] = false;
            } else {
              this.integrationOptions[integrationId]['linkedAccount'] = null;
              this.loaderId[integrationId] = false;
            }
          }
          break;
        }
        case 'zoom': {
          this.updateIntegrationConnectionStatus(
            'zoom',
            this.providerProfile.isZoomAuthVerified
          );
          if (this.providerProfile && this.providerProfile.isZoomAuthVerified) {
            this.loaderId[integrationId] = true;
            let userInfo = await this._providerService.getZoomUserInfo(
              this.providerProfile.id
            );
            if (userInfo && userInfo['email']) {
              this.integrationOptions[integrationId]['linkedAccount'] =
                userInfo['email'];
              this.loaderId[integrationId] = false;
            } else {
              this.integrationOptions[integrationId]['linkedAccount'] = null;
              this.loaderId[integrationId] = false;
            }
          }
          break;
        }

        default:
          break;
      }
    } catch (error) {
      this.loaderId[integrationId] = false;
    }
  }

  initOnlineConsultPlatformConfig() {
    // console.log(
    //   'called platform init',
    //   this.providerProfile.onlineConsultationConfig
    //     .onlineConsultationPlatformConfig
    // );
    this.onlineConsultationPlatformConfig = {
      googlemeet: { url: '', isDynamic: false },
      zoom: { url: '', meetingId: '', password: '', isDynamic: false },
    };
    if (
      this.providerProfile.onlineConsultationConfig &&
      this.providerProfile.onlineConsultationConfig
        .onlineConsultationPlatformConfig
    ) {
      let platformConfig =
        this.providerProfile.onlineConsultationConfig
          .onlineConsultationPlatformConfig;
      let plain_platformConfig = classToPlain(platformConfig);
      // console.log(plain_platformConfig);
      if (plain_platformConfig && plain_platformConfig['googlemeet']) {
        // console.log(plain_platformConfig.value['googlemeet']);
        this.onlineConsultationPlatformConfig['googlemeet'] =
          plain_platformConfig['googlemeet'];
      }

      if (plain_platformConfig && plain_platformConfig['zoom']) {
        // console.log(plain_platformConfig['zoom']);
        this.onlineConsultationPlatformConfig['zoom'] =
          plain_platformConfig['zoom'];
      }
    } else {
      console.log('not available');
    }
  }

  updateLocalPlatformConfig(key, val) {
    let onlinePlatformConfig =
      this.providerProfile.onlineConsultationConfig
        .onlineConsultationPlatformConfig;
    if (onlinePlatformConfig) {
      let _val = plainToClass(ConsultationPlatformConfig, val);
      onlinePlatformConfig.set(key, _val);
    } else {
      onlinePlatformConfig = new Map<string, ConsultationPlatformConfig>();
      let _val = plainToClass(ConsultationPlatformConfig, val);
      onlinePlatformConfig.set(key, _val);
    }
  }

  async setProfileConsultPlatformConfig(integrationId: string) {
    try {
      switch (integrationId) {
        case 'googlemeet': {
          let json = this.onlineConsultationPlatformConfig['googlemeet'];
          await this._providerService.updateOnlinePlatformDetails(
            this.providerProfile.id,
            integrationId,
            json
          );
          this.updateLocalPlatformConfig(integrationId, json);
          break;
        }
        case 'zoom': {
          let json = this.onlineConsultationPlatformConfig['zoom'];
          await this._providerService.updateOnlinePlatformDetails(
            this.providerProfile.id,
            integrationId,
            json
          );
          this.updateLocalPlatformConfig(integrationId, json);
          break;
        }
      }
    } catch (error) {}
  }

  gotoMenu() {
    this._router.navigate(['../'], { relativeTo: this._route });
  }
  redirectToAuthPage(url: string) {
    // window.open(url,"Ratting","width=550,height=170,left=150,top=200,toolbar=0,status=0,scrollbars=1")
    window.open(url, '_self');
  }
}
