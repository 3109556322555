import { Expose, plainToClass, Transform, Type } from "class-transformer";
import { OnlineConsultationSettings } from "../../Entities/OnlineConsultationSettings";
import { ReasonOfVisit } from "../../Entities/ReasonOfVisit";
import { Schedule } from "../../Entities/Schedule";
import { OnlineConsultationPlatform } from "../../Enums/Enums";
import { OtherInfo } from "./OtherInfo";

/**
 * Purpose: To reduce the doctor listing object size. Lite version of DoctorListingProfile class.
 */
export class DoctorShortProfile {
  @Expose() id: string;
  @Expose() name: string;
  @Expose() gender: string;
  @Expose() experienceInYears: string;
  @Expose() type: string;
  @Expose() photoUrl: string;
  @Expose() city: string;
  @Expose() specializations: string[];
  @Expose() languages: string[];
  @Expose() about: string;
  @Expose() isMedicalRegistrationVerified: boolean = false;
  @Expose() isRCILicenseVerified: boolean = false;
  @Expose() isQueerAffirmative: boolean = false;

  @Expose() totalAppointmentsAtDocVita:number;
  @Expose() nextAvailableDate:string;
  @Expose() nextAvailableTime:string;
  @Expose() nextAvailableUpdatedOn:number;

  @Type(() => OnlineConsultationSettings)
  @Expose()
  onlineConsultationConfig: OnlineConsultationSettings = new OnlineConsultationSettings();

  @Type(() => OtherInfo)
  @Expose()
  otherInfo: OtherInfo = new OtherInfo();

  @Expose()
  blockedSlots: any;

  @Expose()
  openSlots: any;

  @Expose()
  @Type(() => Schedule)
  schedule: Schedule[];

  @Expose()
  uniqueTag: string;

  @Expose()
  providerFullName: string; //expvar

  @Expose()
  spokenLanguagesSeparatedByWithComma: string; //expvar

  @Expose() profileLink: string;//expvar

  @Expose()
  specializationDisplay: string; //expvar

  @Expose()
  masterSpecialization: string; //expvar

  @Expose()
  onlineFees: number; //expvar

  @Expose()
  instantconsultFeesListedPrice: number; //expvar

  @Expose()
  instantconsultFees: number; //expvar

  @Expose()
  isInstantBookingOn: boolean; //expvar

  @Expose()
  isAcceptingOnlineBookingsOnDocVita: boolean; //expvar

  @Expose()
  isAcceptingInPersonBookingsOnDocVita: boolean; //expvar
  
  @Expose()
  isPsychologist: boolean; //expvar
  
  @Expose()
  reasonsOfVisitDisplayText: string;

  @Expose()
  reasonsOfVisitDisplayArray: string[];

  @Expose() thumbnailUrl:string;
  get photoUrlForListing(): string {
    return this.thumbnailUrl != null && this.thumbnailUrl.length > 0
    ?this.thumbnailUrl:(this.photoUrl != null && this.photoUrl.length > 0
      ? this.photoUrl
      : "https://default-docvita-images.s3.ap-south-1.amazonaws.com/no-image-available.jpg");
  }

  @Expose()
  @Transform(
    (value) => {
      if (value) {
        let map = new Map<string, ReasonOfVisit>();
        for (let entry of Object.entries(value)) {
          map.set(entry[0], plainToClass(ReasonOfVisit, entry[1]));
        }
        return map;
      } else {
        return new Map<string, ReasonOfVisit>();
      }
    },
    { toClassOnly: true }
  )
  reasonsOfVisit: Map<string, ReasonOfVisit>;

  public get appointmentDuration(): number {
    if (
      this.onlineConsultationConfig &&
      this.onlineConsultationConfig.appointmentDuration
    ) {
      return this.onlineConsultationConfig.appointmentDuration;
    } else if (
      this.schedule &&
      this.schedule.length > 0 &&
      this.schedule[0].timeSlotLength
    ) {
      return this.schedule[0].timeSlotLength;
    } else {
      return 0;
    }
  }

  getCityForUrl() {
    let cityVal = this.city;
    if (cityVal != null) {
      cityVal = cityVal.toLowerCase();
      cityVal = cityVal.split(" ").join("-");
    }
    return cityVal;
  }

  getProfileLink() {
    let str = "";
    if (this.uniqueTag != null && this.uniqueTag.length > 0) {
      str +=
        "https://docvita.com/" +
        this.getCityForUrl() +
        "/" +
        this.type +
        "/" +
        this.uniqueTag;
    }
    return str;
  }

  getProfileLinkRelativePath() {
    let str = "";
    if (this.uniqueTag != null && this.uniqueTag.length > 0) {
      str +=
       "/" +
        this.getCityForUrl() +
        "/" +
        this.type +
        "/" +
        this.uniqueTag;
    }
    return str;
  }
}
