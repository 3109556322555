import {
  animate,
  AUTO_STYLE,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { Directive, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import { DvStepInfo, DvStepService } from "./service/dv-step.service";
import { Subscription } from "rxjs";

const OPEN_DURATION = 200;
const CLOSE_DURATION = 200;

export const collapseAnimation = trigger("collapse", [
  state("false", style({ height: AUTO_STYLE, visibility: AUTO_STYLE })),
  state("true", style({ height: "0", visibility: "hidden" })),
  transition("false => true", animate(OPEN_DURATION + "ms ease-in")),
  transition("true => false", animate(CLOSE_DURATION + "ms ease-out")),
]);

@Directive()
export class DVBaseStepComponent implements OnInit, OnDestroy {
  thisStepNumber: number;
  currentStep: number;
  private currentStepSubscription: Subscription;
  isCollapsed: boolean = false;
  finalProcessLoader: boolean = false;

  @Output() currentStepChanged = new EventEmitter<DvStepInfo>();

  constructor(protected dvStepService: DvStepService) {
  }

  ngOnInit(): void {
    this.subscribeToCurrentStep();
  }

  subscribeToCurrentStep() {
    this.currentStepSubscription = this.dvStepService.currentStep$.subscribe(
      (stepInfo: DvStepInfo) => {
        this.currentStep = stepInfo.currentStep;
        this.finalProcessLoader = stepInfo.finalProcessLoader;
        this.updateCollapsedState();
        this.currentStepChanged.emit(stepInfo);
      }
    );
  }

  updateCollapsedState() {
    if (this.thisStepNumber == null) {
      return;
    }
    this.isCollapsed =
      this.finalProcessLoader || !(this.currentStep == this.thisStepNumber);
  }

  get isAtThisStep(): boolean {
    return this.currentStep != null && this.currentStep == this.thisStepNumber;
  }

  get isAfterThisStep(): boolean {
    return this.currentStep != null && this.currentStep > this.thisStepNumber;
  }

  toggleCardOpenClose() {
    if (
      this.currentStep == null ||
      this.currentStep <= this.thisStepNumber ||
      this.finalProcessLoader
    ) {
      return;
    }
    this.isCollapsed = !this.isCollapsed;
    if(!this.isCollapsed) {
      //Make this a current step
      this.dvStepService.updateStepInfo(this.thisStepNumber, false);
    }
  }

  stepIsComplete() {
    console.log("PENDING CHANGE TO NEW STRUCTURE. FIX THIS");
    // this.dvStepService.stepIsComplete(this.thisStepNumber);
  }

  ngOnDestroy() {
    this.currentStepSubscription.unsubscribe();
  }
}
