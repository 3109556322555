import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-reschedule-request-modal',
  templateUrl: './reschedule-request-modal.component.html',
  styleUrls: ['./reschedule-request-modal.component.scss']
})
export class RescheduleRequestModalComponent implements OnInit {

  title: string = "Request Sent!";
  closeBtnName: string = "OK";

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit(): void {
  }

}
