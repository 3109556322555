export const IconPath = {
  arrowBackIcon: "https://docvita-icons.s3.ap-south-1.amazonaws.com/icons/arrow_back.svg",
  greenCheckIcon:
    "https://docvita-icons.s3.ap-south-1.amazonaws.com/icons/green_check_circle.svg",

  chevronLeftIcon:
    "https://docvita-icons.s3.ap-south-1.amazonaws.com/icons/chevron-left-black.svg",

  chevronDownIcon: "https://docvita-icons.s3.ap-south-1.amazonaws.com/icons/chevron_down_2x.svg",
  chevronUpIcon: "https://docvita-icons.s3.ap-south-1.amazonaws.com/icons/chevron_up_2x.svg",

  locationOnFilledIcon:
    "https://docvita-icons.s3.ap-south-1.amazonaws.com/icons/location_icon.svg",
  queerAffirmativeIcon:
    "https://docvita-icons.s3.ap-south-1.amazonaws.com/icons/lgbtq.png",
  newAtDocVitaIcon:
    "https://docvita-icons.s3.ap-south-1.amazonaws.com/icons/new.svg",
  topRatedIcon:
    "https://docvita-icons.s3.ap-south-1.amazonaws.com/icons/top-rated.svg",
  verifiedIcon:
    "https://docvita-icons.s3.ap-south-1.amazonaws.com/icons/verified.svg",
  instantConfirmationIcon:
    "https://docvita-icons.s3.ap-south-1.amazonaws.com/icons/instant.svg",
  thumbsUpIcon:
    "https://docvita-icons.s3.ap-south-1.amazonaws.com/icons/happy_sessions.svg",
  checkmarkIcon: 
    "https://docvita-icons.s3.ap-south-1.amazonaws.com/icons/checkmark.svg",
};
