import { ToastrService } from "ngx-toastr";
import { DoctorListingProfile } from "src/app/shared/models-ts/Actors/Doctor/DoctorListingProfile";
import { Lead } from "src/app/shared/models-ts/Entities/Lead";
import { AppointmentService } from "src/app/shared/services/appointment/appointment.service";
import { ProviderService } from "src/app/shared/services/provider-service/provider.service";

export class MeetingLinkHelper {
    async meetingLinkButtonActions(key: string, 
        lead: Lead, 
        _aptService: AppointmentService,
        _toastr: ToastrService,
        _providerService: ProviderService,
        ) {
            let creatingLink: boolean = false;
            let linkToDisplay: string;
        try {
            switch (key) {
              case 'googlemeet': {
                if (lead && lead.id) {
                  creatingLink = true;
                  let meetlink = await _aptService.createGoogleMeeting(
                    lead
                  );
                  console.log("mee",meetlink, lead.id)
                  if (meetlink) {
                    linkToDisplay = meetlink;
                    creatingLink = false;
                    _toastr.success('Meeting Info Updated!');
                    // this.meetingPopup.close();
                    // this.linkCreated = true;
                  } else {
                    creatingLink = false;
                    // this.meetingPopup.close();
                    // this.linkCreated = false;
                    _toastr.warning('Failed to create link');
                  }
                  return linkToDisplay;
                } else {
                  _toastr.warning('Something went wrong. Try again.');
                  return null;
                }
                break;
              }
      
              case 'zoom': {
                if (lead && lead.id) {
                  creatingLink = true;
                  let zoomlink = await _aptService.createZoomMeeting(lead);
                  if (zoomlink) {
                    linkToDisplay = zoomlink;
                    creatingLink = false;
                    _toastr.success('Meeting Info Updated!');
                  } else {
                    creatingLink = false;
                    // this.meetingPopup.close();
                    // this.linkCreated = false;
                    _toastr.warning('Failed to create link');
                  }
                  return linkToDisplay;
                } else {
                  _toastr.warning('Something went wrong. Try again.');
                  return null;
                }
                break;
              }
      
              case 'enable_googlemeet': {
                let meetUrl = await _providerService.getGoogleMeetAuthUrl(
                    lead.consultationInfo.doctorId
                );
                if (meetUrl) window.open(meetUrl, '_self');
                return null;
                // this._router.navigate(['dashboard/integrations']);
                break;
              }
      
              case 'enable_zoom': {
                // this._router.navigate(['dashboard/integrations']);
                let meetUrl = await _providerService.getZoomAuthUrl(
                    lead.consultationInfo.doctorId
                );
                if (meetUrl) window.open(meetUrl, '_self');
                return null;
                break;
              }
      
              default:
                return null;
                break;
            }
          } catch (error) {}
      }
}