import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class PlatformCheckService {

  constructor(@Inject(PLATFORM_ID) private platformId: Object) { 
    // console.log("PlatformCheckService")
  }

  isThisServer() {
    return isPlatformServer(this.platformId)
  }

  isThisClient() {
    return isPlatformBrowser(this.platformId)
  }
}
