import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  EventEmitter,
} from '@angular/core';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-date-picker-modal',
  templateUrl: './date-picker-modal.component.html',
  styleUrls: ['./date-picker-modal.component.scss'],
})
export class DatePickerModalComponent implements OnInit {
  inlineRangeValue: Date[];
  bsConfig: Partial<BsDatepickerConfig> = {
    containerClass: 'theme-dark-blue',
    customTodayClass: 'custom-datepicker',
  };

  @Input() dateRange: any = { start: new Date(), end: new Date() };
  @Output() setDateRange: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('datePickerModal', { read: TemplateRef })
  datePickerModal: TemplateRef<any>;

  constructor(private _modalService: BsModalService) {}

  openModal() {
    this.initDateRangePicker();
    this._modalService.show(this.datePickerModal, { class: 'modal-lg' });
  }

  initDateRangePicker() {
    if (this.dateRange) {
      this.inlineRangeValue = [this.dateRange['start'], this.dateRange['end']];
    } else {
      this.inlineRangeValue = [new Date(), new Date()];
    }
  }

  sendDateRange() {
    let dateRange = {
      start: this.inlineRangeValue[0],
      end: this.inlineRangeValue[1],
    };
    this.setDateRange.emit(dateRange);
    this.closeModal();
  }
  closeModal() {
    this._modalService.hide();
  }

  ngOnInit(): void {
    this.initDateRangePicker();
  }
}
