<ng-template #datePickerModal>
    <div class="modal-header">
        <h5 class="modal-title pull-left">Select Date Range</h5>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="date-picker-container">
            <bs-daterangepicker-inline class="custom-datepicker" [bsConfig]="bsConfig" [(bsValue)]="inlineRangeValue"></bs-daterangepicker-inline>
        </div>
        
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default" (click)="closeModal()">Close</button>
        <button type="button" class="btn btn-info ml-2" (click)="sendDateRange()">Ok</button>
    </div>
</ng-template>
