import { Injectable } from '@angular/core';
import { db } from '../../firebaseHelper/firebaseHelper';
import {
  doc,
  collection,
  query,
  where,
  orderBy,
  setDoc,
  getDocs,
  onSnapshot,
  QueryConstraint,
  limit,
} from 'firebase/firestore';
import { plainToClass } from 'class-transformer';
import { Lead } from '../../models-ts/Entities/Lead';
import { environment as env } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AppointmentService {
  constructor(private _http: HttpClient) {}

  getAppointmentByDate(
    providerId: string,
    startDate: string,
    endDate: string,
    order: string,
    listingOption: string,
    _limit?: number
  ) {
    // console.log('Start date: ', startDate);
    // console.log('End date: ', endDate);
    let leadsRef = collection(db, 'patient_leads');

    let orderCheck =
      order && order == 'bookingDate'
        ? orderBy('consultationInfo.date', 'desc')
        : orderBy('createdOn', 'desc');

    let qConstraints: QueryConstraint[] = [];
    let today = new Date().toISOString().split('T')[0];
    if (listingOption == 'upcoming') {
      qConstraints.push(where('consultationInfo.date', '>=', today));
      // qConstraints.push(where('consultationInfo.status', '==', 'CONFIRMED'));
    } else if (listingOption == 'pending') {
      qConstraints.push(where('consultationInfo.status', '==', 'PENDING'));
    } else if (listingOption == 'previous') {
      qConstraints.push(where('consultationInfo.date', '<', today));
      qConstraints.push(limit(30));
    } else if (listingOption == 'date_range') {
      if (startDate != null && startDate.length > 0) {
        if (order != null && order == 'bookingDate') {
          // console.log('Using booking constraint for startDate');
          qConstraints.push(where('consultationInfo.date', '>=', startDate));
        } else {
          let d = new Date(startDate);
          d.setHours(0, 1, 0, 0);
          qConstraints.push(where('createdOn', '>=', d.getTime()));
        }
      }
      if (endDate != null && endDate.length > 0) {
        if (order != null && order == 'bookingDate') {
          // console.log('Using booking constraint for endDate');
          qConstraints.push(where('consultationInfo.date', '<=', endDate));
        } else {
          let d = new Date(endDate);
          d.setHours(23, 59, 0, 0);
          qConstraints.push(where('createdOn', '<=', d.getTime()));
        }
      }
    } else {
      let today = new Date().toISOString().split('T')[0];
      qConstraints.push(where('consultationInfo.date', '>=', today));
    }

    if (_limit) {
      qConstraints.push(limit(_limit));
    }
    // console.log('Order check: ', orderCheck);
    // console.log('q constraints: ', qConstraints);
    let q = query(
      leadsRef,
      where('docvitaId', '==', providerId),
      ...qConstraints,
      orderCheck
    );
    return q;
  }

  async getLeadsForAppointmentByDate(providerId: string, date: string) {
    try {
      const _q = this.getAppointmentByDate(
        providerId,
        date,
        date,
        'bookingDate',
        'date_range'
      );
      const leadSnap = await getDocs(_q);
      if (leadSnap.empty) {
        return [];
      }
      let leads = leadSnap.docs.map((v) => plainToClass(Lead, v.data()));
      leads.sort((a, b) => {
        return a?.consultationInfo
          ?.getStartTime()
          .localeCompare(b?.consultationInfo?.getStartTime());
      });
      return leads;
    } catch (error) {
      return [];
    }
  }

  async createZoomMeeting(lead: Lead) {
    try {
      if (lead.id && lead.consultationInfo && lead.onlineMeetingDetails) {
        let body = {
          consultationInfo: lead.consultationInfo,
          onlineMeetingDetails: lead.onlineMeetingDetails,
        };
        body = JSON.parse(JSON.stringify(body));
        let url = env['create-zoom-link'];
        let zoomLink = await this._http.post(url, body).toPromise();
        // console.log(zoomLink);
        if (
          zoomLink &&
          zoomLink['statusCode'] == 200 &&
          zoomLink['body']['success'] == true &&
          zoomLink['body']['meetingUrl']
        ) {
          return zoomLink['body']['meetingUrl'];
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch (error) {}
  }

  async createGoogleMeeting(lead: Lead) {
    try {
      if (lead.id && lead.consultationInfo) {
        let body = {
          consultationInfo: lead.consultationInfo,
          onlineMeetingDetails: lead.onlineMeetingDetails,
        };
        body = JSON.parse(JSON.stringify(body));
        let url = env['create-googlemeet-link'];
        let googleLink = await this._http.post(url, body).toPromise();
        console.log(googleLink);
        if (
          googleLink &&
          googleLink['statusCode'] == 200 &&
          googleLink['body']['success'] == true &&
          googleLink['body']['meetingUrl']
        ) {
          return googleLink['body']['meetingUrl'];
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async updateConsultationJSON(leadId: string, jsonVal) {
    try {
      let url = env['update-consult-config-json'];
      let body = {
        leadId,
        jsonVal,
      };
      let updateJson = await this._http.post(url, body).toPromise();
      if (
        updateJson &&
        updateJson['statusCode'] == 200 &&
        updateJson['body']['success'] == true
      ) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async cancelAppointment(leadId: string, consultationInfo) {
    try {
      let body = {
        leadId,
        consultationInfo,
      };
      let url = env['cancel-appointment'];
      let cancel = await this._http.post(url, body).toPromise();
      if (
        cancel &&
        cancel['statusCode'] == 200 &&
        cancel['body']['success'] == true
      ) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async rescheduleAppointment(
    leadId: string,
    newConsultationInfo,
    oldConsultationInfo
  ) {
    try {
      let body = {
        leadId,
        newConsultationInfo,
        oldConsultationInfo,
      };
      body = JSON.parse(JSON.stringify(body));
      let url = env['reschedule-appointment'];
      let reschedule = await this._http.post(url, body).toPromise();
      if (
        reschedule &&
        reschedule['statusCode'] == 200 &&
        reschedule['body']['success'] == true
      ) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  }
  async bookAppointment(body) {
    try {
      body = JSON.parse(JSON.stringify(body));
      let bookAppt = await this._http
        .post(env['book-appointment'], body)
        .toPromise();
      if (
        bookAppt &&
        bookAppt['statusCode'] == 200 &&
        bookAppt['body']['success'] == true
      ) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }

  async sendSlackWhatsappChannelTrigger(message: string) {
    try {
      if (!message || message == '') return false;
      let body = { text: message };
      body = JSON.parse(JSON.stringify(body));
      let trigger = await this._http
        .post(env['send-slack-whatsapp-channel-trigger'], body)
        .toPromise();
      if (
        trigger &&
        trigger['statusCode'] == 200 &&
        trigger['body']['success'] == true
      ) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }
}
